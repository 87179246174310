import React from 'react';
import SubSystem from '../subsystems/SubSystem';

const rotations = [
    [Math.PI / 2, Math.PI, 0],
    [Math.PI / 2, -Math.PI / 2, 0],
    [Math.PI / 2, Math.PI, Math.PI / 2],
    [Math.PI / 2, Math.PI / 2, 0],
]

const isRoomyModel = (props) => props.modelID === 10 || props.modelID === 23;

function SideInfill(props) {
    const { side, columns, length, int_length, isRoomy, uID } = props;
    const pos_list = [];
    const list = [];
    const bias = isRoomy ? 24 : 0;

    // console.log("roomy ID props==> ", columns.addedSubSystem)

    if (true && (side === 2 || side === 4)) {
        const sID = columns.systemId[side === 2 ? bias + 0 : bias + 1];
        if (isRoomyModel(props) && side === 2 && !isRoomy) return null;
        if (isRoomyModel(props) && side === 4 && isRoomy) return null;
        if (columns.addedSubSystem[bias + side / 2 - 1]) {
            if (!isRoomyModel(props)) {
                if (length.depth <= 4013) {
                    list.push(
                        <SubSystem
                            key={uID - 2}
                            px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                            py={int_length.oy - length.depth / 2 - props.dy}
                            pz={-int_length.oz}
                            rotation={rotations[side - 1]}
                            width={length.depth - int_length.column_depth}
                            height={length.height1 - props.dz}
                            int_div_width={int_length.int_div_width}
                            max_div_width={int_length.max_div_width}
                            unit={props.unit}
                            ID={side === 2 ? bias + 0 : bias + 1}
                            systemID={sID}
                            isRoomy={isRoomy}
                            scale={props.unit}
                            type={2}
                            side={side}
                            systemType={1}
                            modelID={props.modelID}
                            isTexture={props.isTexture}
                            texture={props.texture}
                        />
                    );
                } 
                else {
                    const wunit = (length.depth - int_length.column_depth);
                    const dy = length.depth / 2;
                    const dx = length.width / 2;
                    list.push(
                        <SubSystem
                            key={uID - 2}
                            px={(-dx + (sID < 3 ? 90 : 30)) * (3 - side)}
                            py={(int_length.oy - dy - props.dy)}
                            pz={-int_length.oz}
                            rotation={rotations[side - 1]}
                            width={wunit}
                            height={length.height1 - props.dz}
                            int_div_width={int_length.int_div_width}
                            max_div_width={int_length.max_div_width}
                            unit={props.unit}
                            ID={side === 2 ? bias + 0 : bias + 1}
                            systemID={sID}
                            isRoomy={isRoomy}
                            scale={props.unit}
                            type={2}
                            side={side}
                            systemType={1}
                            modelID={props.modelID}
                            isTexture={props.isTexture}
                            texture={props.texture}
                        />
                    );
                    // list.push(
                    //     <SubSystem
                    //         key={uID - 3}
                    //         px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                    //         py={(int_length.oy - length.depth / 2 - props.dy) + wunit / 2}
                    //         pz={-int_length.oz}
                    //         rotation={rotations[side - 1]}
                    //         width={wunit}
                    //         height={length.height1 - props.dz}
                    //         int_div_width={int_length.int_div_width}
                    //         max_div_width={int_length.max_div_width}
                    //         unit={props.unit}
                    //         ID={(side === 2 ? bias + 0 : bias + 1) + 6}
                    //         systemID={sID}
                    //         isRoomy={isRoomy}
                    //         scale={props.unit}
                    //         type={2}
                    //         side={side}
                    //         systemType={1}
                    //         modelID={props.modelID}
                    //         isTexture={props.isTexture}
                    //         texture={props.texture}
                    //     />
                    // );
                }
            } else {
                if (length.depth <= 3000 - (150/2)) {
                    list.push(
                        <SubSystem
                            key={uID - 2}
                            px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                            py={int_length.oy - length.depth / 2 - props.dy - 42.5}
                            pz={-int_length.oz}
                            rotation={rotations[side - 1]}
                            width={length.depth - int_length.column_depth + 85}
                            height={length.height1 - props.dz}
                            int_div_width={int_length.int_div_width}
                            max_div_width={int_length.max_div_width}
                            unit={props.unit}
                            ID={side === 2 ? bias + 0 : bias + 1}
                            systemID={sID}
                            isRoomy={isRoomy}
                            scale={props.unit}
                            type={2}
                            side={side}
                            systemType={1}
                            modelID={props.modelID}
                            isTexture={props.isTexture}
                            texture={props.texture}
                        />
                    );
                } 
                else {
                    // const wunit = (length.depth - int_length.column_depth) / 2;
                    const wunit = (length.depth - int_length.column_depth);
                    const dy = length.depth; // / 2;
                    const dx = length.width / 2;
                    list.push(
                        <SubSystem
                            key={uID - 2}
                            px={(-dx + (sID < 3 ? 90 : 30)) * (3 - side)}
                            py={(int_length.oy - dy - props.dy) + wunit / 2}
                            pz={-int_length.oz}
                            rotation={rotations[side - 1]}
                            width={wunit}
                            height={length.height1 - props.dz}
                            int_div_width={int_length.int_div_width}
                            max_div_width={int_length.max_div_width}
                            unit={props.unit}
                            ID={side === 2 ? bias + 0 : bias + 1}
                            systemID={sID}
                            isRoomy={isRoomy}
                            scale={props.unit}
                            type={2}
                            side={side}
                            systemType={1}
                            modelID={props.modelID}
                            isTexture={props.isTexture}
                            texture={props.texture}
                        />
                    );
                    // list.push(
                    //     <SubSystem
                    //         key={uID - 3}
                    //         px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                    //         py={(int_length.oy - dy - props.dy) + wunit / 2}
                    //         pz={-int_length.oz}
                    //         rotation={rotations[side - 1]}
                    //         width={wunit}
                    //         height={length.height1 - props.dz}
                    //         int_div_width={int_length.int_div_width}
                    //         max_div_width={int_length.max_div_width}
                    //         unit={props.unit}
                    //         ID={(side === 2 ? bias + 0 : bias + 1) + 6}
                    //         systemID={sID}
                    //         isRoomy={isRoomy}
                    //         scale={props.unit}
                    //         type={2}
                    //         side={side}
                    //         systemType={1}
                    //         modelID={props.modelID}
                    //         isTexture={props.isTexture}
                    //         texture={props.texture}
                    //     />
                    // );
                }
            }
            if (columns.addedForte[bias + side / 2 - 1]) {
                list.push(
                    <SubSystem
                        key={uID - 1}
                        px={(-length.width / 2) * (3 - side) - 50 * (side - 3)}
                        py={int_length.oy - length.depth / 2}
                        pz={-int_length.oz}
                        rotation={rotations[side - 1]}
                        width={length.depth - int_length.column_depth}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={side === 2 ? bias + 0 : bias + 1}
                        systemID={6}
                        scale={props.unit}
                        type={2}
                        side={side}
                        systemType={2}
                        modelID={props.modelID}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            }
            return list;
        }
        return null;
    }
    else {
        if (!isRoomyModel(props) && !(props.modelID === 22 || props.modelID === 6)) {
            pos_list.push({ pos: columns.pos[0] + int_length.column_width, id: 0 });
        }
        pos_list.push({ pos: length.width, id: 0 })
        for (let i = 2; i < 24; i++) {
            if (columns.added[i] || i == 3) {
                pos_list.push({ pos: columns.pos[i], id: i });
            }
            pos_list.sort(function (a, b) { return a.pos - b.pos });
        }
        pos_list.sort(function (a, b) { return a.pos - b.pos });
        console.log("roomy pos list==> ", pos_list)
        for (let i = 0; i < pos_list.length - 1; i = i + 1) {
            let ID = pos_list[i].id;
            let extra_pos = int_length.column_width / 4;
            // handle roomy left/right side left
            if (ID === 3) {
                ID = 2;
            } else {
                // handle grande front righ position
                if (ID === 2) {
                    extra_pos = 0;
                }
                ID = ID + 2;
            }
            if (columns.addedSubSystem[bias + ID] === false) continue;
            // console.log(`pos list lenth grande index-${i}`)
            const swidth = pos_list[i + 1].pos - pos_list[i].pos - (int_length.column_width + 42.5);
            const px = pos_list[i].pos + (int_length.column_width / 2) + extra_pos;
            list.push(
                <SubSystem
                    key={i}
                    px={-px - swidth / 2 + length.width / 2}
                    py={int_length.oy - 150}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={swidth}
                    height={length.height1}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={columns.systemId[bias + ID]}
                    unit={props.unit}
                    ID={bias + ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={1}
                    modelID={props.modelID}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
            if (columns.addedForte[bias + ID] === false) continue;
            list.push(
                <SubSystem
                    key={i + 100}
                    px={-px - swidth / 2 + length.width / 2}
                    py={int_length.oy}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={swidth}
                    height={length.height1}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={6}
                    unit={props.unit}
                    ID={bias + ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={2}
                    modelID={props.modelID}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
        }
        return list;
    }
}

export default function SideInfills3(props) {
    const list = [];
    for (let i = 0; i < 4; i = i + 1) {
        if (props.walls.added[i]) continue;
        if (i === 2) continue;
        list.push(
            <SideInfill
                key={i}
                uID={i}
                side={i + 1}
                columns={props.columns}
                length={props.length}
                int_length={props.int_length}
                systemID={props.systemID}
                unit={props.int_length.unit}
                dy={props.dy}
                dz={props.dz}
                modelID={props.modelID}
                isRoomy={props.isRoomy}
                isTexture={props.isTexture}
                texture={props.texture}
                walls={props.walls}
            />
        )
    }
    return list;
}
