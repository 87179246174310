import React, { Suspense, useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { OrbitControls, PerspectiveCamera, Sky } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { ContactShadows } from "@react-three/drei";
import { useSnapshot } from "valtio";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import MainApp from "apps/MainApp";
import AppContext from "context/AppContext";
import { autoRotateSpeed, axiosInstanceAuthorized, axiosInstancePublic, steps } from "utils/constant";
import axios from "axios";
import { API_URL, matchUrlTitle, modelIDLinks } from "utils/constant";

import Setting from "common/LeftSide/Setting";
import Loader3d from "common/Loading/Loader3d";
import ModelLog from "common/ModelLogo";
import Option from "common/Option";
import TopToolbar from "common/TopToolbar";
import Balance from "common/Balance";
import HorizontalLinearStepper from "common/Bottom/Stepper";
import OverviewTable from "common/LeftSide/OverviewTable";
import { GetEmbedKey } from "./GetEmbedKey";
import state from "state";
import FriendForm from "common/FriendForm";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import storageService from "services/storage.service";

const Model = (props) => {
  const { key, id } = useParams();
  const [searchParams, _] = useSearchParams();
  const snap = useSnapshot(state);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [available, setAvailable] = useState(true);
  const {
    activeStep,
    setActiveStep,
    subStep,
    setSubStep,
    sidebar,
    setModelID,
    bladeOption,
    setBladeOption,
    setInstall,
    setMode,
    setBrandColor,
  } = useContext(AppContext);

  const unit = searchParams.get("unit");
  const urlLang = searchParams.get("lang");

  const { t, i18n } = useTranslation();

  const isTablet = useMediaQuery("(max-width:899px)");

  const user = JSON.parse(localStorage.getItem("user"));
  const embed_key = key === undefined || key === null ? id : key;
  useEffect(() => {
    if (urlLang) {
      i18n.changeLanguage(urlLang.toLowerCase());
    } else {
      const lang = storageService.get('lang');
      i18n.changeLanguage(lang.toLowerCase());
    }
    if (embed_key && embed_key !== "")
    axiosInstanceAuthorized
        .get(API_URL + "auth/getActiveUser", {
          params: { embed_key: embed_key },
        })
        .then((response) => {
          setActiveUser(response.data.user);
        });
    if (props.modelID) {
      setModelID(props.modelID);
    }

    axiosInstancePublic
      .post(API_URL + "product/available", {
        link: modelIDLinks[props.modelID],
      })
      .catch(() => {
        setAvailable(false);
      });
  }, []);

  useEffect(() => {
    if (!available) throw new Error(`Product_not_assigned`);
  }, [available]);

  if (!unit && !key && !user) {
    navigate("/login", { replace: true });
    return;
  }

  var mode = 0;
  if (key !== undefined) {
    setMode(1);
    mode = 1;
    // localStorage.removeItem("user");
    // localStorage.clear();
  }

  if (mode === 1 && !GetEmbedKey(key)) return null;
  if (mode === 1 && unit !== undefined && unit !== null && unit !== "mm" && unit !== "inch")
    return null;
  if (mode === 1 && unit) localStorage.setItem("unit", unit);
  if (mode === 1 && unit === undefined) localStorage.setItem("unit", "inch");

  const isEmbed = mode === 1;

  const isLast = activeStep === steps[props.modelID].length - 1;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {(sidebar || isLast) && !isTablet ? (
          <Grid
            item
            lg={isLast ? 12 : 3}
            md={isLast ? 12 : 4}
            sm={isLast ? 12 : 8}
            xs={isLast ? 12 : 12}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            {isLast ? <OverviewTable /> : <Setting activeUser={activeUser} />}
          </Grid>
        ) : null}
        <Grid
          item
          lg={sidebar ? 9 : 12}
          md={sidebar ? 8 : 12}
          sm={12}
          xs={12}
          className="main"
          sx={{ display: isLast ? "none" : "block" }}
        >
          <TopToolbar group={props.group} modelID={0} setOpen={setOpen} />
          <ModelLog />
          <Canvas
            className={sidebar === true ? "canvas" : "canvas-1"}
            camera={{ position: [0, 0, -4], fov: 50 }}
            shadows
          >
            {props.modelID === 10 || props.modelID === 23 ? (
              <PerspectiveCamera position={[4, 0, 0]} makeDefault />
            ) : props.modelID === 3 ||
              props.modelID === 5 ||
              props.modelID === 7 ? (
              <PerspectiveCamera position={[2, 2, 2]} makeDefault />
            ) : props.modelID === 6 || props.modelID === 22 ? (
              <PerspectiveCamera position={[-2.5, 1.5, -2.5]} makeDefault />
            ) : props.modelID === 11 ||
              props.modelID === 16 ||
              props.modelID === 20 ||
              props.modelID === 9 ? (
              <PerspectiveCamera position={[0, 0, -4]} makeDefault />
            ) : (
              <PerspectiveCamera position={[0, 0, 4]} makeDefault />
            )}
            <Sky
              distance={450000}
              sunPosition={[5, 1, 8]}
              inclination={0}
              azimuth={0.25}
            />
            {true || !snap.hasLight ? (
              <>
                <ambientLight intensity={0.3} />
                <spotLight
                  castShadow
                  intensity={0.4}
                  angle={0.5}
                  penumbra={1}
                  position={[5, 25, 20]}
                />
              </>
            ) : null}
            <directionalLight
              intensity={false && snap.hasLight ? 0.2 : 2.0}
              position={[2, 11, 5]}
              castShadow
              shadow-mapSize-width={2048}
              shadow-mapSize-height={2048}
            />
            <directionalLight intensity={2.0} position={[-2, 11, -5]} />
            <Suspense fallback={<Loader3d />}>
              <mesh
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -1.22, 0]}
              >
                <boxGeometry attach="geometry" args={[8, 8, 1.5]} />
                <meshPhongMaterial attach="material" color="white" />
              </mesh>
              <mesh rotation={[Math.PI / 2, 0, 0]} position={[0, 2.5, 0]}>
                <planeGeometry attach="geometry" args={[8, 8, 1.5]} />
                <meshPhongMaterial
                  attach="material"
                  color="#dddddd"
                  transparent
                  opacity={0.1}
                />
              </mesh>

              <mesh rotation={[0, -Math.PI / 2, 0]} position={[4, 0, 0]}>
                <planeBufferGeometry attach="geometry" args={[8, 5]} />
                <meshPhongMaterial
                  attach="material"
                  color="#dddddd"
                  transparent
                  opacity={0.3}
                />
              </mesh>
              <mesh rotation={[0, Math.PI / 2, 0]} position={[-4, 0, 0]}>
                <planeBufferGeometry attach="geometry" args={[8, 5]} />
                <meshPhongMaterial
                  attach="material"
                  color="#dddddd"
                  transparent
                  opacity={0.3}
                />
              </mesh>
              <mesh rotation={[0, 0, 0]} position={[0, 0, -4]}>
                <planeBufferGeometry attach="geometry" args={[8, 5]} />
                <meshPhongMaterial
                  attach="material"
                  color="#dddddd"
                  transparent
                  opacity={0.3}
                />
              </mesh>
              <mesh rotation={[0, Math.PI, 0]} position={[0, 0, 4]}>
                <planeBufferGeometry attach="geometry" args={[8, 5]} />
                <meshPhongMaterial
                  attach="material"
                  color="#dddddd"
                  transparent
                  opacity={0.3}
                />
              </mesh>
              <MainApp
                modelID={props.modelID}
                setModelID={setModelID}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setSubStep={setSubStep}
                subStep={subStep}
                setInstall={setInstall}
                group={props.group}
                setMode={setMode}
                setBrandColor={setBrandColor}
              />
              <ContactShadows
                rotation-x={Math.PI / 2}
                position={[0, -0.8, 0]}
                opacity={0.25}
                width={10}
                height={10}
                blur={1.5}
                far={0.8}
              />
            </Suspense>

            <OrbitControls
              minDistance={1.5}
              maxDistance={10}
              enableZoom={true}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={-Math.PI / 2}
              autoRotate={true}
              autoRotateSpeed={autoRotateSpeed}
            />
          </Canvas>
        </Grid>
        { isTablet ?
          <Grid
            item
            xs={12}
            sx={{ display: { md: "none", xs: "block" }, mb: "113px" }}
          >
            {bladeOption ? (
              <Option setShowOption={setBladeOption} />
            ) : isLast ? (
              <OverviewTable />
            ) : (
              <Setting activeUser={activeUser} />
            )}
          </Grid> : null
        }
      </Grid>
      <HorizontalLinearStepper />

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Send To Friend</DialogTitle>
        <DialogContent>
          <FriendForm handleClose={handleClose} modelID={props.modelID} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default Model;
