
export function calcDivConfig(props) {
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, top_width, int_div_width, unit } = props.int_length;

  // const top_current_width = top_width + width - int_width;
  const top_current_width = top_width + width - int_width;
  const div_count = Math.round(top_current_width / int_div_width, 0);
  const div_width = top_current_width / div_count;
  console.log('div_count', div_count, div_width);
  return { div_count, div_width };
}


// let geometries = [];
//   const div_front = nodes.dividerprofile.geometry.clone();
//   div_front.translate(0, 0, 0);
//   div_front.scale(1, 0.5, 1)
//   div_front.rotateX(-2.85)
//   geometries.push(div_front);
//   const div_back = nodes.dividerprofile.geometry.clone();
//   div_back.translate(0, 124, 65)
//   div_back.scale(1, 0.9, 1)
//   div_back.rotateY(Math.PI);
//   div_back.rotateX(2.01);
//   geometries.push(div_back);
  
//   // merge
//   const compactGeom = mergeBufferGeometries(geometries);