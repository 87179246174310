import { useSnapshot } from 'valtio';
import state from '../state';
import { secondHeight } from '../utils/constant';

export default function SideCount(modelID) {
    const snap = useSnapshot(state);
    const columns = snap.columns;
    var cnt = 0;

    if (secondHeight[modelID]) {
        for (let i = 0; i < 22; i = i + 1) {
            if (columns.added[i] === false && i > 0) continue;
            if (i === 1) continue;
            if (columns.addedSubSystem[i + 2] === false) {
                cnt = cnt + 1;
            }
        }
        if (modelID !== 10 && columns.addedSubSystem[0] === false) cnt = cnt + 1;
        if (columns.addedSubSystem[1] === false) cnt = cnt + 1;

        // Polad New model
        if (modelID === 24) {
            if (columns.addedSubSystem[6] === false) cnt = cnt + 1;
            if (columns.addedSubSystem[7] === false) cnt = cnt + 1;
        }
    
        if (modelID === 10) {
            for (let i = 0; i < 22; i = i + 1) {
                if (columns.added[i] === false && i > 0) continue;
                if (i === 1) continue;
                if (columns.addedSubSystem[24 + i + 2] === false) {
                    cnt = cnt + 1;
                }
            }
            if (columns.addedSubSystem[24 + 0] === false) cnt = cnt + 1;
            // if (columns.addedSubSystem[24 + 1] === false) cnt = cnt + 1;
        }
    }
    else {
        for (let i = 0; i < 24; i = i + 1) {
            if (columns.added[i] === false) continue;
            if (columns.addedSubSystem[i] === false) cnt = cnt + 1;
        }
        if (modelID === 1 || modelID === 4 || modelID === 2 || modelID === 8 || modelID === 13 || modelID === 15 || modelID === 14 || modelID === 17 || modelID === 19) {
            for (let i = 0; i < 24; i = i + 1) {
                if (columns.added[i + 24] === false) continue;
                if (columns.addedSubSystem[i + 24] === false) cnt = cnt + 1;
            }
        }
    }
    return cnt;
}
