import React from 'react'

import * as THREE from 'three'
import { useSnapshot } from "valtio"
import { useGLTF, useTexture } from '@react-three/drei'
import { useLoader } from '@react-three/fiber';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Html } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";

import {
  lengths,
  columnFlag,
  default_forte_pos,
  wallThickness,
  wallTopHeight,
  secondHeight
} from 'utils/constant';
import state from "state";
import Wall from '../Wall';
import { TriPart } from './TriPart';

import dynamicModel from '../../assets/models/dynamic.glb';
import dynamicTopModel from '../../assets/models/dynamic_new_detail.glb';
import cabbana from "../../assets/models/cabbana_new_1.glb";
import SideInfills2 from '../SideInfills2';
import TopInfills2 from '../TopInfills2';
import { TopDepthDivCount, TopDivCount } from '../DivCount';
import GetLogo from '../dynamic/GetLogo';

// dev
// import { useControls } from 'leva';
// import { useRef } from 'react';
// import { Mesh } from 'three';

const xrepeat = 2, yrepeat = 2;


export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness, column_depth } = props.int_length;
  const col_d = 200;
  const tck = 300;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - tck * 2) / (depth - col_d / 2));
  const alpha2 = Math.atan(Math.abs(int_height2 - int_height1 - tck * 2) / (int_depth - col_d / 2));
  if (height1 > height2 - tck * 2) return - (alpha1 + alpha2);
  return alpha1 - alpha2;
}

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness } = props.int_length;
  const h1 = Math.abs(height1 - height2 - thickness);
  const h2 = Math.abs(int_height1 - int_height2 - thickness);
  const delta = 600;
  const l1 = (h1 * h1 + (depth - delta) * (depth - delta));
  const l2 = (h2 * h2 + (int_depth - delta) * (int_depth - delta));
  return Math.sqrt(l1 / l2);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

function NormalizeGeometry(geometry) {
  const uvAttribute = geometry.getAttribute('uv');

  // Find min/max values for u and v
  let uMin = Number.POSITIVE_INFINITY;
  let uMax = Number.NEGATIVE_INFINITY;
  let vMin = Number.POSITIVE_INFINITY;
  let vMax = Number.NEGATIVE_INFINITY;

  for (let i = 0; i < uvAttribute.count; i++) {
    const u = uvAttribute.getX(i);
    const v = uvAttribute.getY(i);

    uMin = Math.min(uMin, u);
    uMax = Math.max(uMax, u);
    vMin = Math.min(vMin, v);
    vMax = Math.max(vMax, v);
  }

  // Normalize the values
  const uRange = uMax - uMin;
  const vRange = vMax - vMin;

  for (let i = 0; i < uvAttribute.count; i++) {
    const u = uvAttribute.getX(i);
    const v = uvAttribute.getY(i);

    const nu = (u - uMin) / uRange;
    const nv = (v - vMin) / vRange;

    uvAttribute.setXY(i, nu, nv);
  }

  // Update the attribute in the geometry
  geometry.setAttribute('uv', uvAttribute);
  return geometry;
}

function NormalGeoWithoutUV(geometry) {
  const positions = geometry.getAttribute('position');

  const uvs = [];

  let minX = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxZ = -Infinity;

  for (let i = 0; i < positions.length; i += 3) {
    const x = positions[i];
    const z = positions[i + 2];

    if (x < minX) {
      minX = x;
    }

    if (z < minZ) {
      minZ = z;
    }

    if (x > maxX) {
      maxX = x;
    }

    if (z > maxZ) {
      maxZ = z;
    }
  }

  for (let i = 0; i < positions.length; i += 3) {
    const x = positions[i];
    const z = positions[i + 2];

    const u = (x + Math.abs(minX)) / (Math.abs(minX) + maxX);
    const v = (z + Math.abs(minZ)) / (Math.abs(minZ) + maxZ);

    uvs.push(u, v);
  }

  // Add uv attribute to geometry
  const uvAttribute = new THREE.Float32BufferAttribute(uvs, 2);
  geometry.setAttribute('uv', uvAttribute);

  return geometry;

}

export function WaterProfile(props) {
  const { nodes, materials } = useGLTF(cabbana);
  const { color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, water_profile_width } = props.int_length;
  const dh1 = height1 - int_height1;
  const sw = (width + water_profile_width - int_width) / (water_profile_width);
  const cabbana_profile_width = 3740.07080078125;
  let sd2 = (width/cabbana_profile_width);
  const sw2 = width / int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  // texture.rotation = Math.PI / 2;
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
    side: THREE.DoubleSide,
  });

  return (
    <group
      position={[0, 0, dh1 * unit / 10]}
    >
      <mesh
        castShadow
        geometry={nodes.beam6.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[0, -7, 2100.00]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={[1, 1, sd2]}
        material-color={color}
      />
    </group>
  )
}

export function BackProfile(props) {
  const { nodes, materials } = useGLTF(dynamicTopModel);
  const { color } = props;
  const { width, depth, height2 } = props.length;
  const { int_width, int_depth, int_height2, unit } = props.int_length;
  const dd = depth - int_depth;
  const dh2 = height2 - int_height2;
  const back_profile_width = 4000;
  const sw = width / back_profile_width;
  const alpha = CalcRotation(props);
  // const rotation = [Math.PI / 2 + alpha, 0, 0];
  const rotation = [0.05 + alpha + Math.PI / 2, -Math.PI / 2, 0]
  var texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
    side: THREE.DoubleSide,
  });

  // const geometry = NormalizeGeometry(nodes.top_profile.geometry);

  return (
    <group
      scale={[sw, 1, 1]}
      position={[0, dd * unit / 10, dh2 * unit / 10]}
    >
      <mesh
        castShadow
        geometry={nodes.BackProfile.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        // position={[-40.03, 43271.14, 27344.3]}
        position={[0, 4332.0, 2850]}
        rotation={rotation}
        material-color={color}
      />
    </group>
  )
}

export function ColumnLeft(props) {
  const { nodes, materials } = useGLTF(cabbana);
  const { color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_height } = props.int_length;
  const dw = width - int_width;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat / 2, yrepeat / 6);
  texture.rotation = Math.PI / 3.2;

  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });

  const bottom_z_pos = 98;
  const x_pos = -2900;
  const y_pos = -29.00;

  return (
    <group
      position={[(-dw * unit / 2 + columns.pos[1] * unit) / 10, 0, 0]}
    >
      <mesh
        castShadow
        geometry={nodes.corner1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[x_pos, y_pos, bottom_z_pos + 1702.0 + (height1 - 2000)]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[-1, 1, 1]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.coltop026.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        position={[x_pos + 70, y_pos + 70, bottom_z_pos + 1702.0 + (height1 - 2000)]}
        scale={1}
        rotation={[0, 0, Math.PI / 2]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.column1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[x_pos, y_pos, bottom_z_pos + 860.0 + ((height1 - 2000) / 2)]}
        scale={[1, 1, 1 * (height1 / 2000) - 0.25]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.foot1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
                          // TODO: recheck y
        position={[x_pos, y_pos, bottom_z_pos]}
        material-color={color}
        rotation={[0, 0, -Math.PI/2]}
        scale={1}
        visible={true}
      />
    </group>
  )

}

export function ColumnRight(props) {
  const { nodes, materials } = useGLTF(cabbana);
  const { color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const delta = 150;
  const sh1 = (height1 - delta) / (int_height1 - delta);
  const scale = [1, 1 * sh1, 1]
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat / 2, yrepeat / 6);
  texture.rotation = Math.PI / 3.2;
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });

  const bottom_z_pos = 98;
  const x_pos = 2900;
  const y_pos = -29.00;

  return (
    <group
      position={[(dw * unit / 2 - columns.pos[0] * unit) / 10, 0, 0]}
    >
      <mesh
        castShadow
        geometry={nodes.corner1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[x_pos, y_pos, bottom_z_pos + 1702.0 + (height1 - 2000)]}
        rotation={[0, 0, 0]}
        scale={[-1, 1, 1]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.coltop026.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        position={[x_pos - 70, y_pos + 70, bottom_z_pos + 1702.0 + (height1 - 2000)]}
        scale={1}
        rotation={[0, 0, 0]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.column1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
        position={[x_pos, y_pos, bottom_z_pos + 860.0 + ((height1 - 2000) / 2)]}
        scale={[1, 1, 1 * (height1 / 2000) - 0.25]}
        material-color={color}
        visible={true}
      />
      <mesh
        castShadow
        geometry={nodes.foot1.geometry}
        material={props.isTexture ? material_texture : materials.profile}
                          // TODO: recheck y
        position={[x_pos, y_pos, bottom_z_pos]}
        material-color={color}
        rotation={[0, 0, Math.PI/2]}
        scale={1}
        visible={true}
      />
    </group>
  )

}

export function Columns(props) {
  const { nodes, materials } = useGLTF(cabbana);
  const { color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const delta = 150;
  const sh1 = (height1 - delta) / (int_height1 - delta);
  const scale = [1, 1 * sh1, 1]
  const px2 = 28090.68;
  const py = 59.41;
  const pz = 0.52;
  const list = [];
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat / 2, yrepeat / 6);
  texture.rotation = Math.PI / 3.2;
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });

  const bottom_z_pos = 98;
  const x_pos = 2900;
  const y_pos = -29.00;

  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <group
          position={[(dw * unit / 2 - columns.pos[i] * unit + column_thickness * unit / 2)/10, 0, 0]}
        >
          <mesh castShadow
            geometry={nodes.column7.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[x_pos,y_pos, bottom_z_pos + 860.0 + ((height1 - 2000) / 2)]}
            scale={[1, 1, 1 * (height1 / 2000) - 0.25]}
            material-color={color}
            visible={true}
          />
          <mesh
            castShadow
            geometry={nodes.foot1.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[x_pos, y_pos, bottom_z_pos]}
            material-color={color}
            rotation={[0, 0, 0]}
            scale={1}
            visible={true}
          />
        </group>
      )
    }
  }
  return (
    <group>
      {list}
    </group>
  )

}


export function Glass(props) {
  const { nodes, materials, coverColor, topPos } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, int_div_width, top_glass_width, top_glass_height, max_div_width, column_width, unit } = props.int_length;
  const dwidth = width - column_width;
  // const texture = useTexture(props.bladeTexture);
  // texture.wrapS = THREE.RepeatWrapping;
  // texture.wrapT = THREE.RepeatWrapping;
  // texture.repeat.set(xrepeat, yrepeat);

  const texture1 = useTexture(props.texture);
  texture1.wrapS = THREE.RepeatWrapping;
  texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture1,
  });

  const dh1 = height1 - int_height1;
  const tmp_count = parseInt(dwidth / int_div_width);
  const tmp_width = dwidth / tmp_count;
  var div_count = 0;
  if (props.modelID === 3 || props.modelID === 5) {
    var tmp = 0;
    for (var i = 2; i < 20; i = i + 1) {
      if (props.addedSubSystem[i] || props.deletedSubSystem[i]) tmp = tmp + props.subSystemCount[i];
    }
    div_count = tmp;
  }
  else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  }
  div_count = TopDivCount(width, props.modelID);
  const div_width = dwidth / div_count;
  const xdelta = (div_width - 100 - top_glass_width) / 2;
  const px = -23035.59 + xdelta;
  const list = [];
  const depth_div_count = TopDepthDivCount(depth, props.modelID);
  const scale = [(div_width - 100) / top_glass_width, depth_div_count > 2 ? 1 : 1.5, 1];

  const rotation = [0.1, 0, 0];

  const my1 = topPos / 100 * (depth_div_count > 2 ? 28500 : 21400);
  const mz1 = my1 * Math.sin(0.1);
  const my2 = Math.max(0, topPos - 50) / 100 * 28500 * (depth_div_count > 2 ? 1 : 0);
  const mz2 = my2 * Math.sin(0.1);

  for (var i = 0; i < div_count; i = i + 1) {
    list.push(
      <group
        key={i}
        position={[-(width - int_width) * unit / 2 + div_width * i * unit + (div_width - top_glass_width) * unit / 2, 0, 0]}
      >
        {!props.isDesign ?
          <group>
            <mesh geometry={nodes.glasspanel111.geometry} material={materials.glass} position={[px, 285.89 + my1, 22608.46 + mz1]} rotation={rotation} scale={scale} material-color={'#a7c7cb'}>
              <meshPhysicalMaterial
                transparent
                ditherTransparent
                thickness={1}
                opacity={0.5}
                reflectivity={0.3}
                // map={texture}
                castShadow
              />
            </mesh>
            <mesh geometry={nodes.windowprofile11.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 129.69 + my1, 22642 + mz1]} rotation={rotation} scale={scale}
              material-color={coverColor}
            />
            <group position={[0, 7200 * (3 - depth_div_count), 600 * (3 - depth_div_count)]}>
              <mesh geometry={nodes.glasspanel6.geometry} material={materials.glass} position={[px, 14031.78 + my2, 24483.8 + mz2]} rotation={rotation} scale={scale} material-color={'#a7c7cb'}>
                <meshPhysicalMaterial
                  transparent
                  ditherTransparent
                  thickness={1}
                  opacity={0.5}
                  reflectivity={0.3}
                  // map={texture}
                  castShadow
                />
              </mesh>
              <mesh geometry={nodes.windowprofile6.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 14304.91 + my2, 24559.54 + mz2]} rotation={rotation} scale={scale}
                material-color={coverColor}
              />
            </group>
            {depth_div_count > 2 ?
              <>
                <mesh geometry={nodes.glasspanel1.geometry} material={materials.glass} position={[px, 28218.86, 26403]} rotation={rotation} scale={scale}
                  material-color={'#a7c7cb'}
                >
                  <meshPhysicalMaterial
                    transparent
                    ditherTransparent
                    thickness={1}
                    opacity={0.5}
                    reflectivity={0.3}
                    // map={texture}
                    castShadow
                  />
                </mesh>
                <mesh geometry={nodes.windowprofile1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 28440.1, 26473.65]} rotation={rotation} scale={scale}
                  material-color={coverColor}
                />
              </> : null}
          </group>
          :
          <group>
            <mesh geometry={nodes.glasspanel111.geometry} material={materials.glass} position={[px, 285.89 + my1, 22608.46 + mz1]} rotation={rotation} scale={scale}
            />
            <mesh geometry={nodes.windowprofile11.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 129.69 + my1, 22642 + mz1]} rotation={rotation} scale={scale}
              material-color={coverColor}
            />
            <mesh geometry={nodes.glasspanel6.geometry} material={materials.glass} position={[px, 14031.78 + my2, 24483.8 + mz2]} rotation={rotation} scale={scale}
            />
            <mesh geometry={nodes.windowprofile6.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 14304.91 + my2, 24559.54 + mz2]} rotation={rotation} scale={scale}
              material-color={coverColor}
            />
            {depth_div_count > 2 ?
              <>
                <mesh geometry={nodes.glasspanel1.geometry} material={materials.glass} position={[px, 28218.86, 26403]} rotation={rotation} scale={scale}
                />
                <mesh geometry={nodes.windowprofile1.geometry} material={props.isTexture ? material_texture : materials.profile} position={[px, 28440.1, 26473.65]} rotation={rotation} scale={scale}
                  material-color={coverColor}
                />
              </> : null}
          </group>}
      </group>
    )
  }
  return (
    <group
      position={[0, 0, dh1 * unit]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = 23;
          state.editingSystemType = 1;
        }
      }
    >
      {list}
    </group>
  )

}

export function TopDiv(props) {
  const { nodes: NNodes, materials: NMaterials } = useGLTF(dynamicTopModel);
  const { nodes, materials, coverColor, visible } = props;
  const { width, height1, depth } = props.length;
  const { int_width, int_height1, int_depth, int_div_width, max_div_width, unit, column_width, column_depth } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const list = [];
  const dwidth = width - column_width;
  const tmp_count = parseInt(dwidth / int_div_width);
  const tmp_width = dwidth / tmp_count;

  // const div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  var div_count = 0;
  if (props.modelID === 3 || props.modelID === 5) {
    var tmp = 0;
    for (var i = 2; i < 20; i = i + 1) {
      if (props.addedSubSystem[i] || props.deletedSubSystem[i]) tmp = tmp + props.subSystemCount[i];
    }
    div_count = tmp;
  }
  else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  }
  div_count = TopDivCount(width, props.modelID);
  const div_width = dwidth / div_count;
  const px = -28250 - dw * unit / 2;
  const py = -41.40;
  const pz = 22935.25;
  const scale = [10, 10, 10];
  const rotation = [-1.465, 0, Math.PI];
  const light_geometry = new THREE.BoxGeometry(200, 100, 2000);
  const light_material = new THREE.MeshLambertMaterial({ color: '#ffffff' })
  const texture = useTexture(props.textureS);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 3.5;
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });

  const profile_scale = [10, 10, 22.3]

  list.push(
    <group key={-1} position={[-dw * unit / 2, 0, 0]}>
      <mesh
        castShadow
        geometry={NNodes.topProfile.geometry}
        material={props.isTexture ? material_texture : NMaterials.profile}
        position={[-28650, py, pz]}
        rotation={rotation}
        scale={profile_scale}
        material-color={coverColor}
      />
      <mesh
        castShadow
        geometry={NNodes.Cap.geometry}
        material={props.isTexture ? material_texture : NMaterials.profile}
        position={[-28650, py - 10, pz]}
        rotation={rotation}
        scale={[10, 10, 20]}
        material-color={coverColor}
      />
    </group>
  )
  list.push(
    <group key={0} position={[dw * unit / 2, 0, 0]}>
      <mesh
        castShadow
        geometry={NNodes.topProfile.geometry}
        material={props.isTexture ? material_texture : NMaterials.profile}
        position={[28650, py, pz]}
        rotation={rotation}
        scale={profile_scale}
        material-color={coverColor}
      />
      <mesh
        castShadow
        geometry={NNodes.Cap.geometry}
        material={props.isTexture ? material_texture : NMaterials.profile}
        position={[28650, py - 10, pz]}
        rotation={rotation}
        scale={[10, 10, 20]}
        material-color={coverColor}
      />
    </group>
  )

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <group key={i}>
        <mesh
          key={i}
          castShadow
          geometry={NNodes.topProfile.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[px + i * div_width * unit, py, pz]}
          rotation={rotation}
          scale={profile_scale}
          material-color={coverColor}
        />
        <mesh
          castShadow
          geometry={NNodes.Cap.geometry}
          material={props.isTexture ? material_texture : NMaterials.profile}
          position={[px + i * div_width * unit, py - 10, pz]}
          rotation={rotation}
          scale={[10, 10, 20]}
          material-color={coverColor}
        />
        {visible ? <>
          <mesh key={div_count + i} geometry={light_geometry} material={light_material} position={[px + i * div_width * unit, py + 14500, pz - 150]} rotation={rotation} scale={scale} />
          <mesh key={div_count * 2 + i} geometry={light_geometry} material={light_material} position={[px + i * div_width * unit, py + 29000, pz + 1300]} rotation={rotation} scale={scale} />
        </> : null}
      </group>
    )
  }
  return (
    <group
      position={[0, 0, dh1 * unit]}
    >
      {list}
    </group>
  )

}


export function CapLeft(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });

  return (
    <group
      position={[-dw * unit / 2, 0, dh1 * unit]}
    >
      <mesh castShadow geometry={nodes.LeftCap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[-30039.79, -152.84, 21672.59]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} material-color={color} />
    </group>
  )
}

export function CapRight(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshLambertMaterial({
    map: texture,
  });
  return (
    <group
      position={[dw * unit / 2, 0, dh1 * unit]}
    >
      <mesh castShadow geometry={nodes.RightCap.geometry} material={props.isTexture ? material_texture : materials.profile} position={[30009.29, -152.84, 21672.59]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} material-color={color} />
    </group>
  )
}

export function Lights(props) {
  const { nodes, materials, visible } = props;
  const { width, depth, height1, height2 } = props.length;
  const { int_width, int_depth, int_height1, int_height2, int_div_width, max_div_width, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const dh2 = height2 - int_height2;
  const list = [];
  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  const div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  const div_width = width / div_count;
  const px = -20000 - dw * unit / 2;
  const py = 705.91;
  const pz = 9303.8;
  const alpha = CalcRotation(props);
  const rotation = [Math.PI / 2 + alpha, 0, 0];

  for (var i = 1; i < div_count; i = i + 1) {
    list.push(
      <mesh key={i} castShadow geometry={nodes.left_lights.geometry} material={materials.light} position={[px + i * div_width * unit, py, pz]} rotation={rotation} scale={10} />
    )
  }

  return visible ? (
    <group
      position={[0, dd * unit / 2, dh1 * unit / 2 + dh2 * unit / 2]}
    >
      {list}
    </group>
  ) : null;

}

export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[0, -oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[-width * unit / 2, (depth / 2 - oy - 100) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (depth - oy) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[width * unit / 2, (depth / 2 - oy - 100) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return props.activeStep === 0 && props.subStep === 1 && columns.adding === true ? (
    <group>
      {columnFlag[4] === true ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[4] === false || columns.added[5] === false) props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (!columns.added[4]) props.ShiftColumn('E')
              else if (!columns.added[5]) props.ShiftColumn('F')
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[5] === false) props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[5] === false) props.ShiftColumn('F') }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[6] === false) props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[6] === false) props.ShiftColumn('G') }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[7] === false) props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[7] === false) props.ShiftColumn('H') }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {!secondHeight && walls.added[0] === false ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[1] === false ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Left Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[2] === false ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[3] === false ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}


export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, LineColor, LineEndColor, lengthEndLineWidth, lengthInfoDist } = props.int_length;
  const points = []
  points.push(new THREE.Vector3(0, 0, 0))
  points.push(new THREE.Vector3(width * unit, 0, 0))
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points)
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0))
  points2.push(new THREE.Vector3(0, depth * unit, 0))
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(points2)
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0))
  points3.push(new THREE.Vector3(0, 0, height1 * unit));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(points3)
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0))
  points4.push(new THREE.Vector3(0, 0, height2 * unit));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(points4)

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group>
      <Html scaleFactor={5} position={[0, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">{parseInt(width * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>

      <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, (-oz + 200) * unit]}>
        <div className="length-info">{parseInt(depth * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      <Html scaleFactor={5} position={[(width + 100) * unit / 2, -oy * unit, (height1 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      {secondHeight ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height2 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html> : null}

      <line geometry={lineGeometryForWidth} position={[-width * unit / 2, (-oy - lengthInfoDist) * unit, -oz * unit + (height1) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForDepth} position={[width * unit / 2, -oy * unit, (-oz) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight1} position={[width * unit / 2, -oy * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight2} position={[width * unit / 2, (depth - oy) * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[-width * unit / 2, (-oy - lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy - lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + lengthEndLineWidth / 2) * unit, (-oz + height1) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth + lengthEndLineWidth / 2) * unit, (-oz + height2) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>



      <line geometry={lineGeometryForEnd} position={[width * unit / 2, -oy * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (-oy + depth) * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>


    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, secondHeight } = props.int_length;
  const columns = props.columns;
  const columnFlag = props.columnFlag;

  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <Html key={i} scaleFactor={5} position={[width * unit / 2 - columns.pos[i] * unit, -oy * unit, (height1 - oz) * unit]}>
          <div className="length-info">{String.fromCharCode(65 + i)}</div>
        </Html>
      )
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>
      {columnFlag[0] ? <Html scaleFactor={5} position={[width * unit / 2 - columns.pos[0] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">A</div>
      </Html> : null}
      {columnFlag[1] ? <Html scaleFactor={5} position={[-width * unit / 2 + columns.pos[1] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">B</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[-width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">C</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">D</div>
      </Html> : null}
      {list}
    </group>
  ) : null;

}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { unit, oy } = props.int_length;

  return (
    <>
      <group position={[0, (depth - 150) * unit, height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[2] ?
          <Wall width={width} height={height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation={[Math.PI / 2, 0, 0]} ID={2} />
          : null}
      </group>
      <group position={[-width * unit / 2, (depth) * unit / 2, height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[1] ?
          <Wall width={depth} height={height2} unit={unit} position={[-wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={1} />
          : null}
      </group>
      <group position={[width * unit / 2, (depth) * unit / 2, height2 / 2 * unit + wallTopHeight * unit / 2]}>
        {walls.added[3] ?
          <Wall width={depth} height={height2} unit={unit} position={[wallThickness * unit / 2, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]} ID={3} />
          : null}
      </group>
    </>
  )
}


export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

function Top(props) {
  const { height1, height2, depth } = props.length;
  const { int_height1, int_depth, int_height2, column_depth } = props.int_length;


  const alpha = CalcRotation(props);
  const sd = (depth - column_depth / 2) / (int_depth - column_depth / 2) + alpha * 0.18;

  // const meshRef = useRef(null);
  // if (meshRef.current) {
  //   console.log("lp", meshRef.current.geometry, [
  //     meshRef.current.geometry.boundingBox.max.y - meshRef.current.geometry.boundingBox.min.y,
  //   ])
  // }

  return (
    <group position={[0, 0, 22935.25 - 400]} rotation={[alpha + 0.005, 0, 0]} scale={[1, sd, 1]}>
      <group position={[0, 0, -22935.25]} >
        <TopDiv {...props} />
        <Glass {...props}/>
      </group>
    </group>
  )
}

function AddSubSystems(props) {
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { columns } = props;

  const list = [];
  const pos_list = [];

  for (let i = 2; i < 24; i++) {
    if (columns.added[i]) {
      pos_list.push({ pos: columns.pos[i], id: i });
    }
  }
  pos_list.push({ pos: columns.pos[0], id: 0 });
  pos_list.push({ pos: width, id: 0 })
  pos_list.sort(function (a, b) { return a.pos - b.pos });

  for (let i = 0; i < pos_list.length - 1; i = i + 1) {
    const swidth = pos_list[i + 1].pos - pos_list[i].pos;
    const px = pos_list[i].pos;
    const ID = pos_list[i].id;
    list.push(
      <Html key={i} scaleFactor={5} position={[width * unit / 2 - pos_list[i].pos * unit - swidth * unit / 2, -oy * unit, -oz * unit]}>
        <div
          className="side-info side-info-subsystem"
          onPointerOver={(e) => {
            e.preventDefault();
            props.setSelectedSide(1);
            props.setCurrentWidth(swidth);
            props.setCurrentPos(px + swidth / 2);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            if (props.systemID === 6 && columns.addedSubSystem[ID + 2] === true
              && (columns.systemId[ID + 2] <= 3 || columns.systemId[ID + 2] === 5)) {
              state.columns.addedForte[ID + 2] = true;
              state.columns.fortePos[ID + 2] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[ID + 2] = true;
              state.columns.systemId[ID + 2] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[ID + 2 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
            props.setSelectedSide(-1);
          }}
        >
          <BsPlusCircle />
        </div>
      </Html>

    )
  }

  return (props.activeStep === 2 && columns.addingSubSystem === true) ? (
    <group>
      {/* <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => {
            props.setSelectedSide(1);
          }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            state.walls.added[0] = true;
            state.walls.adding = false;
          }}
        >
          Front Side<BsPlusCircle />
        </div>
      </Html> */}
      <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(2) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[1] === true && (columns.systemId[1] <= 3 || columns.systemId[1] === 5)) {
              state.columns.addedForte[1] = true;
              state.columns.fortePos[1] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[1] = true
              state.columns.systemId[1] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[1 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >
          Left Side<BsPlusCircle />
        </div>
      </Html>
      {/* <Html scaleFactor={5} position={[0, (depth-oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => {props.setSelectedSide(3)}}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            state.walls.added[2] = true;
            state.walls.adding = false;
          }}
        >Back Side<BsPlusCircle />
        </div>
      </Html> */}
      <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
        <div
          className="side-info"
          onPointerOver={(e) => { props.setSelectedSide(4) }}
          onPointerOut={(e) => props.setSelectedSide(-1)}
          onClick={(e) => {
            props.setSelectedSide(-1);
            if (props.systemID === 6 && columns.addedSubSystem[0] === true && (columns.systemId[0] <= 3 || columns.systemId[0] === 5)) {
              state.columns.addedForte[0] = true;
              state.columns.fortePos[0] = default_forte_pos;
            }
            else {
              state.columns.addedSubSystem[0] = true
              state.columns.systemId[0] = props.systemID;
              if (props.systemID === 0) {
                state.columns.systemId[0 + 8] = props.systemID;
              }
            }
            state.columns.addingSubSystem = false;
          }}
        >Right Side<BsPlusCircle />
        </div>
      </Html>
      {list}
    </group>
  ) : null;

}

function SideForSubSystem(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  const { currentPos, currentWidth } = props;
  if (props.selectedSide === 0) return null;
  switch (props.selectedSide) {
    case 1:
      return (
        <mesh
          position={[width * unit / 2 - currentPos * unit, -oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[currentWidth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[-width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (depth - oy) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[width * unit / 2, (depth / 2 - oy) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}


export function DynamicN(props) {
  const modelID = props.modelID;
  const gltf = useLoader(GLTFLoader, dynamicModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  // const onProgress = (xhr) => {
  //   if (xhr.lengthComputable) {
  //     const newProgress = (xhr.loaded / xhr.total) * 100;
  //     props.setProgress(Math.round(newProgress));
  //   }
  // };

  // const gltf = useLoader(GLTFLoader, dynamicModel, onProgress);
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);

  // useEffect(()=>{
  //   setLoaded(true);
  // }, [])

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(dynamicModel)
  const snap = useSnapshot(state);
  const [currentSide, setCurrentSide] = React.useState(0);
  const [currentPos, setCurrentPos] = React.useState(-1);
  const [selectedSide, setSelectedSide] = React.useState(0);
  const [currentWidth, setCurrentWidth] = React.useState(0);

  const ShiftColumn = (column) => {
    state.columns.editing = true;
    state.columns.editingColumn = column;
    state.columns.added[asciiDif(column, 'A')] = true;
    state.columns.adding = false;
    setCurrentSide(0);
  }

  // return !loaded?
  //   <ProgressBar value={progress} text={'Loading Model...'} />:
  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={10}>
        <BackProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
      </group>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={10}>
        <WaterProfile
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <ColumnLeft
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
        />
        <ColumnRight
          nodes={nodes}
          materials={materials}
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
        />
        <Columns
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
          isTexture={snap.isTexture}
          texture={snap.structure.textureA}
        />
      </group>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={1}>
        <Top
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          glassColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          coverColor={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isBladeTexture={snap.isBladeTexture}
          bladeTexture={snap.blades.texture}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
          textureS={snap.structure.textureR}
          opacity={snap.blades.opacity}
          topPos={snap.columns.systemPos[23]}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
          visible={snap.hasLight}
        />

        {/* <CapLeft
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        />
        <CapRight
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isTexture={snap.isTexture}
          texture={snap.structure.texture}
        /> */}
        <ColumnLabels
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        {/* <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={snap.length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        /> */}
        <AddExtraColumns
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <Walls
          walls={snap.walls}
          length={snap.length}
          int_length={lengths[modelID]}
        />
        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />

        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
        />
        <SideInfills2
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          dy={40}
          dz={250}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureB}
          walls={snap.walls}
        />
        <TriPart
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          opacity={snap.blades.opacity}
          modelID={modelID}
          isTexture={snap.isTexture}
          texture={snap.structure.textureC}
          addedSubSystem={snap.columns.addedSubSystem}
          walls={snap.walls}
        />

        <TopInfills2
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={9}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          deletedSubSystem={snap.columns.deletedSubSystem}
          px={0}
          py={-3500}
          pz={2100}
          alpha={-0.03}
          dr={1}
          isTexture={snap.isTexture}
          texture={snap.structure.textureD}
        />

        <GetLogo
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          modelID={modelID}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
        />


        {/* <DynamicOrgin
          nodes={nodes}
          materials={materials}
        /> */}


      </group>
    </group>
  )
}
useGLTF.preload(dynamicModel)
