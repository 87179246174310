import React from 'react';
import SubSystem from '../subsystems/SubSystem';

const rotations = [
    [Math.PI / 2, Math.PI, 0],
    [Math.PI / 2, -Math.PI / 2, 0],
    [Math.PI / 2, Math.PI, Math.PI / 2],
    [Math.PI / 2, Math.PI / 2, 0],
]

function SideInfill(props) {
    const { side: oSide, columns, length, int_length, isRoomy, uID, systemID } = props;
    const pos_list = [];
    const list = [];
    const bias = isRoomy ? 24 : 0;

    let side = oSide;

    if (side === 2 || side === 4 || side === 6 || side === 7) {
        let isFront = false;
        if ([6, 7].includes(oSide)) {
            isFront = false;
        } else {
            isFront = true;
        }

        if (isFront && columns.addedSubSystem[bias + side / 2 - 1]) {
            const sID = columns.systemId[side === 2 ? bias + 0 : bias + 1];
            // const wunit1 = 1200 - 120;
            const back_width = 1000 + (int_length.column_depth*2);
            const wunit2 = (length.depth - back_width - int_length.column_depth);
            const adjustment = back_width;
            if (length.depth <= 4013 && false) {
                list.push(
                    <SubSystem
                        key={uID - 2}
                        px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                        py={int_length.oy - length.depth / 2 - props.dy}
                        pz={-int_length.oz}
                        rotation={rotations[side - 1]}
                        width={length.depth - int_length.column_depth}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={side === 2 ? bias + 0 : bias + 1}
                        systemID={sID}
                        isRoomy={isRoomy}
                        scale={props.unit}
                        type={2}
                        side={side}
                        systemType={1}
                        modelID={props.modelID}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            } else {
                list.push(
                    <SubSystem
                        key={uID - 2}
                        px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - side)}
                        py={(int_length.oy - back_width - props.dy) - wunit2 / 2 + adjustment - 80}
                        pz={-int_length.oz}
                        rotation={rotations[side - 1]}
                        width={wunit2}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={side === 2 ? bias + 0 : bias + 1}
                        systemID={sID}
                        isRoomy={isRoomy}
                        scale={props.unit}
                        type={2}
                        side={side}
                        systemType={1}
                        modelID={props.modelID}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            }

            if (columns.addedForte[bias + side / 2 - 1]) {
                list.push(
                    <SubSystem
                        key={uID - 1}
                        px={(-length.width / 2) * (3 - side) - 50 * (side - 3)}
                        // py={int_length.oy - length.depth / 2}
                        py={(int_length.oy - back_width - props.dy) - wunit2 / 2 + adjustment - 80}
                        pz={-int_length.oz}
                        rotation={rotations[side - 1]}
                        // width={length.depth - int_length.column_depth}
                        width={wunit2}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={side === 2 ? bias + 0 : bias + 1}
                        systemID={6}
                        scale={props.unit}
                        type={2}
                        side={side}
                        systemType={2}
                        modelID={props.modelID}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            }
            return list;
        } else if (!isFront && columns.addedSubSystem[side]) {
            // const wunit1 = 1200 - 120;
            const wunit1 = 1000;
            const back_width = 1000 + (int_length.column_depth*2);
            const wunit2 = (length.depth - back_width - int_length.column_depth);
            const adjustment = back_width;
            const sID = columns.systemId[side];
            const nSide = side === 6 ? 2 : 4;
            list.push(
                <SubSystem
                    key={uID - 3}
                    px={(-length.width / 2 + (sID < 3 ? 90 : 30)) * (3 - nSide)}
                    py={(int_length.oy - length.depth - back_width - props.dy) + wunit1 / 2 + adjustment + 240}
                    pz={-int_length.oz}
                    rotation={rotations[nSide - 1]}
                    width={wunit1}
                    height={length.height1 - props.dz}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    unit={props.unit}
                    ID={(nSide === 2 ? bias + 0 : bias + 1) + 6}
                    systemID={sID}
                    isRoomy={isRoomy}
                    scale={props.unit}
                    type={2}
                    side={nSide}
                    systemType={1}
                    modelID={props.modelID}
                    isTexture={props.isTexture}
                    texture={props.texture}
                    isSingular={true}
                />
            );

            if (columns.addedForte[side]) {
                list.push(
                    <SubSystem
                        key={uID - 1}
                        px={(-length.width / 2) * (3 - nSide) - 50 * (nSide - 3)}
                        py={(int_length.oy - length.depth - back_width - props.dy) + wunit1 / 2 + adjustment + 240}
                        pz={-int_length.oz}
                        rotation={rotations[nSide - 1]}
                        width={wunit1}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={(nSide === 2 ? bias + 0 : bias + 1) + 6}
                        systemID={6}
                        scale={props.unit}
                        type={2}
                        side={nSide}
                        systemType={2}
                        modelID={props.modelID}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            }
            return list;
        }
        return null;
    }
    else {
        pos_list.push({ pos: columns.pos[0] + int_length.column_width, id: 0 });
        pos_list.push({ pos: length.width, id: 0 })
        for (let i = 2; i < 24; i++) {
            if (columns.added[i]) pos_list.push({ pos: columns.pos[i] + int_length.column_width / 2, id: i });
            pos_list.sort(function (a, b) { return a.pos - b.pos });
        }
        // console.log("poladnew pos list==> ", pos_list)
        for (let i = 0; i < pos_list.length - 1; i = i + 1) {
            const ID = pos_list[i].id + 2;
            if (columns.addedSubSystem[bias + ID] === false) continue;
            // console.log(`pos list lenth grande index-${i}`)
            const swidth = pos_list[i + 1].pos - pos_list[i].pos - int_length.column_width;
            // const swidth = pos_list[i + 1].pos - pos_list[i].pos;
            const px = pos_list[i].pos;
            list.push(
                <SubSystem
                    key={i}
                    px={-px - swidth / 2 + length.width / 2}
                    py={int_length.oy - 150}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={swidth}
                    height={length.height1}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={columns.systemId[bias + ID]}
                    unit={props.unit}
                    ID={bias + ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={1}
                    modelID={props.modelID}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
            if (columns.addedForte[bias + ID] === false) continue;
            // const forteWidth = swidth - 140;
            const forteWidth = swidth;
            list.push(
                <SubSystem
                    key={i + 100}
                    px={-px - forteWidth / 2 + length.width / 2}
                    py={int_length.oy}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={forteWidth}
                    height={length.height1}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={6}
                    unit={props.unit}
                    ID={bias + ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={2}
                    modelID={props.modelID}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
        }
        return list;
    }
}

export default function SideInfills7(props) {
    const list = [];
    for (let i = 0; i < 6; i = i + 1) {
        if (props.walls.added[i]) continue;
        if (i === 2) continue;
        let ix = i;
        if (i >= 4) ix = i + 1;
        list.push(
            <SideInfill
                key={ix}
                uID={ix}
                side={ix + 1}
                columns={props.columns}
                length={props.length}
                int_length={props.int_length}
                systemID={props.systemID}
                unit={props.int_length.unit}
                dy={props.dy}
                dz={props.dz}
                modelID={props.modelID}
                isRoomy={props.isRoomy}
                isTexture={props.isTexture}
                texture={props.texture}
                walls={props.walls}
            />
        )
    }
    return list;
}
