import { lazy } from "react";
import MainLayout from "../layouts/MainLayout";
import Loadable from "../components/Loadable";
import AuthGuardEmbed from "../common/authguardembed";

const Model = Loadable(lazy(() => import("../pages/Model")));
const Guillotine = Loadable(lazy(() => import("../pages/Guillotine")));
const LoginEmbed = Loadable(lazy(() => import("../pages/auth/LoginEmbed")));
const RegisterEmbed = Loadable(
  lazy(() => import("../pages/auth/RegisterEmbed"))
);

const MainRoutes = {
  path: "embed",
  element: <MainLayout />,
  children: [
    {
      path: "login/:key",
      element: <LoginEmbed />,
    },
    {
      path: "register/:key",
      element: <RegisterEmbed />,
    },
    {
      path: "point/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={0} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "urban/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={3} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "urbanglass/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={5} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "cabbana/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={1} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "cabbanas/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={13} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "cabbanan/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={17} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "lenore/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={15} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "panora/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={2} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "panoradouble/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={8} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "lanai/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={4} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "lanais/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={14} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "grande/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={6} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "dynamic/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={7} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "pergola/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={9} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "pergolaavantgarde/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={11} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "avantgardes/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={16} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "roomy/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={10} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "polad/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={12} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "guillotine/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={0} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "classy/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={1} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "minima/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={2} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "fixedglass/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={3} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "fixedwall/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={4} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "liftandslidedoor/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={5} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "forte/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={6} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "zion/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={7} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "bifolddoor/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={8} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "capella/:key",
      element: (
        <AuthGuardEmbed>
          <Guillotine systemID={9} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "door/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={18} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "cabbanast/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={19} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "pergolast/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={20} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "dynamicnew/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={21} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "grandenew/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={22} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "roomynew/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={23} />
        </AuthGuardEmbed>
      ),
    },
    {
      path: "poladnew/:key",
      element: (
        <AuthGuardEmbed>
          <Model modelID={24} />
        </AuthGuardEmbed>
      ),
    },
  ],
};

export default MainRoutes;
