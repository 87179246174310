import React, {useEffect} from 'react'
import { useSnapshot } from "valtio"
import { lengths, default_forte_pos } from '../../utils/constant';
import state from "../../state"
import { Html, useGLTF, useHelper, useProgress } from "@react-three/drei";
import { BsPlusCircle } from "react-icons/bs";
import * as THREE from 'three'
import { wallThickness, wallTopHeight } from '../../utils/constant';
import Wall from '../Wall';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';

// dev
// import { useControls } from 'leva';
import { useRef } from 'react';
import { Mesh } from 'three';

import poladModel from '../../assets/models/polad_new.glb'
import panora_parts from "../../assets/models/panora_parts.glb";
import { GlassShader, TopDivBack, TopDivFront, TopPart, TopPartStatic, TopPartVertical } from 'models/polad/TopPart';
import { calcDivConfig } from './helpers';
import { BoxHelper } from 'three';
// import SideInfills3 from '../SideInfills3';
// import TopInfills2 from '../TopInfills2';

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, thickness, column_depth } = props.int_length;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - column_depth / 2));
  const alpha2 = Math.atan(Math.abs(int_height2 - int_height1 - thickness) / (depth - column_depth / 2));

  if (height1 > height2 - thickness) return - (alpha1 + alpha2);
  return alpha1 - alpha2;
}

export const CalcScale = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_depth, int_height1, int_height2, thickness } = props.int_length;
  const h1 = Math.abs(height1 - height2 - thickness);
  const h2 = Math.abs(int_height1 - int_height2 - thickness);
  const delta = 600;
  const l1 = (h1 * h1 + (depth - delta) * (depth - delta));
  const l2 = (h2 * h2 + (int_depth - delta) * (int_depth - delta));
  return Math.sqrt(l1 / l2);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}


export function WaterProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, water_profile_width } = props.int_length;
  const dh1 = height1 - int_height1;
  const sw = (width + water_profile_width - int_width) / (water_profile_width);
  return (
    <group
      position={[0, 0, dh1 * unit]}
      scale={[sw, 1, 1]}
    >
      <mesh castShadow material-color={color} geometry={nodes.underprofile1.geometry} material={materials.profile} position={[0.15, -2364.33, 778.91]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[-1, 1, 1]} />
    </group>
  )
}

export function BackProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit, water_profile_width } = props.int_length;
  const dh1 = height1 - int_height1;
  const dd = depth - int_depth;
  const sw = (width + water_profile_width - int_width) / (water_profile_width);
  return (
    <group
      position={[0, dd * unit, dh1 * unit]}
      scale={[sw, 1, 1]}
    >
      <mesh castShadow material-color={color} geometry={nodes.underprofile3.geometry} material={materials.profile} position={[0.15, 2477.46, 778.89]} rotation={[Math.PI / 2, Math.PI / 2, 0]} />
    </group>
  )
}

export function MiddleProfile(props) {
  const { nodes, materials, color } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit, water_profile_width } = props.int_length;
  const dh1 = height1 - int_height1;
  const dd = depth - int_depth;
  const sw = (width + water_profile_width - int_width) / (water_profile_width);
  return (
    <group
      visible={true}
      position={[0, dd * unit, dh1 * unit]}
      scale={[sw, 1, 1]}
    >
      <mesh castShadow material-color={color} geometry={nodes.underprofile2.geometry} material={materials.profile} position={[0.15, 1319.94, 781.55]} rotation={[Math.PI / 2, Math.PI / 2, 0]} />
    </group>
  )
}

export function LeftProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1, depth } = props.length;
  const { int_width, int_height1, unit, int_depth } = props.int_length;
  const dh1 = height1 - int_height1;
  const sd = depth / int_depth;
  const dw = width - int_width;
  const dd = depth - int_depth;
  return (
    <group
      position={[-dw * unit / 2, dd * unit / 2, dh1 * unit]}
      scale={[1, sd, 1]}
    >
      <mesh castShadow material-color={color} geometry={nodes.sideprofile2.geometry} material={materials.profile} position={[-2912.21, 0.04, 777.9]} rotation={[-Math.PI / 2, 0, Math.PI]} />
    </group>
  )
}

export function RightProfile(props) {
  const { nodes, materials, color } = props;
  const { width, height1, depth } = props.length;
  const { int_width, int_height1, int_depth, unit } = props.int_length;
  const dh1 = height1 - int_height1;
  const sd = depth / int_depth;
  const dw = width - int_width;
  const dd = depth - int_depth;
  return (
    <group
      position={[dw * unit / 2, dd * unit / 2, dh1 * unit]}
      scale={[1, sd, 1]}
    >
      <mesh castShadow material-color={color} geometry={nodes.sideprofile1.geometry} material={materials.profile} position={[2912, 0.04, 777.9]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1, 1, 1]} />
    </group>
  )
}


export function ColumnLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]


  return (
    <group
      position={[-dw * unit / 2 - columns.pos[1] * unit, 0, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.leftcolumn1.geometry} material={materials.profile} position={[-2933.56, -2499.74, -2084.59]} scale={scale} />
    </group>
  )

}

export function ColumnMiddleLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]


  return (
    <group
      position={[-dw * unit / 2 - columns.pos[1] * unit, dd * unit, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.leftcolumn2.geometry} material={materials.profile} position={[-2934.81, 1331.02, -2084.59]} scale={scale} />
    </group>
  )

}
export function ColumnBackLeft(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]


  return (
    <group
      position={[-dw * unit / 2 - columns.pos[1] * unit, dd * unit, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.leftcolumn3.geometry} material={materials.profile} position={[-2934.81, 2499.49, -2084.59]} scale={scale} />
    </group>
  )

}


export function ColumnRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]

  return (
    <group
      position={[dw * unit / 2 + columns.pos[0] * unit, 0, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.rightcolumn1.geometry} material={materials.profile} position={[2933.8, -2499.74, -2084.59]} scale={scale} />
    </group>
  )
}

export function ColumnMiddleRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]

  return (
    <group
      position={[dw * unit / 2 + columns.pos[0] * unit, dd * unit, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.rightcolumn2.geometry} material={materials.profile} position={[2932.56, 1331.02, -2084.59]} scale={scale}/>
    </group>
  )
}

export function ColumnBackRight(props) {
  const { nodes, materials, color, columns } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, unit } = props.int_length;
  const dw = width - int_width;
  const dd = depth - int_depth;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]

  return (
    <group
      position={[dw * unit / 2 + columns.pos[0] * unit, dd * unit, 0]}
    >
      <mesh castShadow material-color={color} geometry={nodes.rightcolumn3.geometry} material={materials.profile} position={[2932.56, 2499.49, -2084.59]} scale={scale} />
    </group>
  )
}


export function Columns(props) {
  const { nodes, materials, color, columns } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit, column_thickness } = props.int_length;
  const dw = width - int_width;
  const sh1 = height1 / int_height1;
  const scale = [1, 1, sh1]
  const px2 = 3110.33;
  const py = 2948.7;
  const pz = -1983.42;
  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <mesh key={i} castShadow geometry={nodes.leftcolumn1.geometry} material={materials.profile} position={[dw * unit / 2 + px2 - columns.pos[i] * unit + column_thickness * unit / 2, py, pz]} scale={scale} material-color={color} />
      )
    }
  }
  return (
    <group>
      {list}
    </group>
  )

}

export function TopFrontLeft(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;

  return (
    <group
      position={[-dw * unit / 2, 0, dh1 * unit]}
    >
      {/* can use Line434 for triangle */}
      <mesh castShadow material-color={color} geometry={nodes.Line434.geometry} material={materials.profile} position={[-2884.14, -577.55, 827.63]} rotation={[-1.22, 0, 0]} />
      <mesh material-color={'#a7c7cb'} geometry={nodes.Line440.geometry} material={materials.glass} position={[-2908.21, -522.82, 1309.91]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[-1, 1, 1]} >
        <meshPhysicalMaterial
          transparent
          ditherTransparent
          thickness={1}
          opacity={0.5}
          reflectivity={0.3}
          // map={texture}
          castShadow
        />
      </mesh>
    </group>
  )
}

export function TopFrontRight(props) {
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;

  return (
    <group
      position={[dw * unit / 2, 0, dh1 * unit]}
    >
      <mesh castShadow material-color={color} geometry={nodes.Line434.geometry} material={materials.profile} position={[2869.6, -577.55, 827.63]} rotation={[-1.22, 0, 0]} scale={[-1, 1, 1]} />
      <mesh material-color={'#a7c7cb'} geometry={nodes.Line429.geometry} material={materials.glass} position={[2893.67, -522.82, 1309.91]} rotation={[Math.PI / 2, Math.PI / 2, 0]} >
        <meshPhysicalMaterial
          transparent
          ditherTransparent
          thickness={1}
          opacity={0.5}
          reflectivity={0.3}
          // map={texture}
          castShadow
        />
      </mesh>
    </group>
  )
}

export function TopHorizontalDiv(props) {
  const { nodes, materials } = props;
  const { width } = props.length;
  const { int_width, horizontal_profile_width } = props.int_length;
  const top_horizontal_width = horizontal_profile_width + width - int_width;
  const sw = top_horizontal_width / horizontal_profile_width;

  return (
    <group
      position={[0, 0, 0]}
      scale={[sw, 1, 1]}
    >
      <mesh geometry={nodes.horizontalprofile1_1.geometry} material={materials.profile} position={[-1.83, 784.02, 2036.7]} rotation={[Math.PI / 2, Math.PI / 2, 0]} />
      <mesh geometry={nodes.horizontalprofile1.geometry} material={materials.profile} position={[-3.62, -773.54, 1476.91]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} />
    </group>
  )

}

export function TopFrontGlass(props) {
  const { nodes: PNodes, materials: PMaterials } = useGLTF(panora_parts);
  const { nodes, materials} = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, top_width, int_div_width, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const list = [];
  const list2 = [];
  // const top_current_width = top_width + width - int_width;
  // const div_count = Math.round(top_current_width / int_div_width, 0);
  // const div_width = top_current_width / div_count;
  const { div_count, div_width } = calcDivConfig(props);
  const mcolor = "#a7c7cb";

  for (var i = 0; i < div_count; i = i + 1) {
    list2.push(
      <group position={[div_width * i, 0, 0]} scale={[1, 1, 1]}>
        <mesh
          castShadow
          geometry={PNodes.dividerprofile.geometry}
          material={PMaterials.All_profiles}
          scale={[10, 12, 10]}
          position={[-1908.07, 5.73 + -770, 1767.26 + -331]}
          rotation={[-1.221905 + Math.PI / 2, 0, 0]}
          material-color={"#ffffff"}
        />
        <mesh
          castShadow
          geometry={PNodes.dividerprofile.geometry}
          material={PMaterials.All_profiles}
          scale={[10, 4.6, 10]}
          position={[-1908.07, 5.73 + 961.00, 1767.26 + -329.00]}
          rotation={[-2.79253 + Math.PI / 2, 0, 0]}
          material-color={"#ffffff"}
        />
      </group>
    )
    list.push(
      <group position={[-div_width * i, 0, 0]} scale={[1, 1, 1]}>
        <mesh 
          visible={true}
          material-color={mcolor} 
          geometry={nodes.glasspanel19.geometry} 
          material={materials.glass} 
          scale={[1, 1, 1 * (div_width / 935.799)]}
          position={[2388.07, -1559.75, 1197.37]} 
          // rotation={[Math.PI / 2, -Math.PI / 2, 0]} 
          rotation={[Math.PI/2, -Math.PI / 2, 0]} 
        >
          <meshPhysicalMaterial
            transparent
            ditherTransparent
            thickness={1}
            opacity={0.7}
            reflectivity={0.3}
            // map={texture}
            castShadow
          />
        </mesh>
        <mesh 
          visible={true}
          material-color={mcolor} 
          geometry={nodes.glasspanel13.geometry} 
          material={materials.glass} 
          position={[2390.6, 5.73, 1767.26]} 
          scale={[1, 1, 1 * (div_width / 932.5)]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        >
          <meshPhysicalMaterial
            transparent
            ditherTransparent
            thickness={1}
            opacity={0.7}
            reflectivity={0.3}
            // map={texture}
            castShadow
          />
        </mesh>
        <mesh 
          visible={true} 
          material-color={mcolor} 
          geometry={nodes.glasspanel7.geometry} 
          material={materials.glass} 
          position={[2387.81, 1019.66, 1464.2]}
          scale={[1, 1, 1 * (div_width / 935.799)]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        >
          <meshPhysicalMaterial
            transparent
            ditherTransparent
            thickness={1}
            opacity={0.7}
            reflectivity={0.3}
            // map={texture}
            castShadow
          />
        </mesh>
      </group>
    )
  }

  return (
    <group
      position={[0, 0, dh1 * unit]}
    >
      <group>
        <TopHorizontalDiv
          {...props}
        />
      </group>
      {/* <group
        visible={false}
        // - 50  -(depth - 9000) * 0.1 / 2
        position={[-dw * unit / 2, 0, 0]}
      >
        {list2}
      </group> */}
      <group
        position={[dw * unit / 2, 0, 0]}
      >
        {list}
      </group>
    </group>
  )

}

export function TopBackGlass(props) {
  const { nodes, materials } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_height1, int_depth, top_width, int_div_width, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const dd = depth - int_depth;
  const list = [];
  // const top_current_width = top_width + width - int_width;
  // const div_count = Math.round(top_current_width / int_div_width, 0);
  // const div_width = top_current_width / div_count;
  const { div_count, div_width } = calcDivConfig(props);

  for (var i = 0; i < div_count; i = i + 1) {
    list.push(
      <group position={[-div_width * i, 0, 0]} scale={[1, 1, 1]}>
        <mesh 
          material-color={'#e87a13'}
          geometry={nodes.glasspanel1.geometry}
          material={materials.glass}
          position={[2382.99, 1969.03, 876.28]}
          scale={[1 * (div_width / 935.943), 1, 1]}
          rotation={[-0.03, 0, 0]}
        >
          <meshPhysicalMaterial
            transparent
            ditherTransparent
            thickness={1}
            opacity={0.7}
            reflectivity={0.3}
            // map={texture}
            castShadow
          />
        </mesh>
      </group>
    )
  }

  return (
    <group
      visible={true}
      position={[dw * unit / 2, dd * unit, dh1 * unit]}
    >
      {list}
    </group>
  )

}


export function TopDiv(props) {
  const { nodes: PNodes, materials: PMaterials } = useGLTF(panora_parts);
  const { nodes, materials, color } = props;
  const { width, height1 } = props.length;
  const { int_width, int_height1, top_width, int_div_width, unit } = props.int_length;
  const dw = width - int_width;
  const dh1 = height1 - int_height1;
  const list = [];
  // const top_current_width = top_width + width - int_width;

  // const div_count = Math.round(top_current_width / int_div_width, 0);
  // const div_width = top_current_width / div_count;
  const { div_count, div_width } = calcDivConfig(props);
  for (var i = 0; i <= div_count; i = i + 1) {
    list.push(
      <>
      {/* <mesh
        castShadow
        material-color={color}
        geometry={nodes.verticalprofile6.geometry}
        material={materials.profile}
        position={[2869.6 - i * div_width, 50.38, 832.2 + 559.00]}
        rotation={[-1.57, -Math.PI / 2, Math.PI]}
        scale={[-1, 1, 1]}
        
      /> */}
      <group position={[-div_width * i, 0, 0]} scale={[1, 1, 1]}>
        <mesh
          castShadow
          geometry={PNodes.dividerprofile.geometry}
          material={PMaterials.All_profiles}
          scale={[10, 12, 10]}
          position={[2869.6, 5.73 + -770, 1767.26 + -331]}
          rotation={[-1.221905 + Math.PI / 2, 0, 0]}
          material-color={color}
        />
        <mesh
          castShadow
          geometry={PNodes.dividerprofile.geometry}
          material={PMaterials.All_profiles}
          scale={[10, 4.6, 10]}
          position={[2869.6, 5.73 + 961.00, 1767.26 + -329.00]}
          rotation={[-2.79253 + Math.PI / 2, 0, 0]}
          material-color={color}
        />
      </group>
      </>
    )
  }

  return (
    <group
      visible={true}
      position={[dw * unit / 2, 0, dh1 * unit]}
    >
      {list}
    </group>
  )

}

export function TopFront(props) {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, int_depth, back_depth } = props.int_length;
  const sh2 = (height2 - height1) / (int_height2 - int_height1);
  const sd = (depth - back_depth - 450) / (int_depth - back_depth - 450);

  const groupRef1 = useRef();
  const groupRef2 = useRef();
  const boxRef1 = useRef(new THREE.Box3());
  const boxRef2 = useRef(new THREE.Box3());

  // useHelper(groupRef1, BoxHelper, "blue");
  // useHelper(groupRef2, BoxHelper, "red");

  useEffect(() => {
    if (groupRef1.current) {
      boxRef1.current.setFromObject(groupRef1.current);
      console.log("grf1", boxRef1.current.max.y - boxRef1.current.min.y, boxRef1.current.max.z - boxRef1.current.min.z);
    }
    if (groupRef2.current) {
      boxRef2.current.setFromObject(groupRef2.current);
      console.log("grf2", boxRef2.current.max.y - boxRef2.current.min.y, boxRef2.current.max.z - boxRef2.current.min.z);
    }
  }, [sh2, sd]);

  return (
    <group 
      ref={groupRef1} 
      position={[0, -2370, 400]} 
      rotation={[0, 0, 0]}
      scale={[1, sd, sh2]}
    >
      {/* <axesHelper args={[500]} /> */}
      <group 
        ref={groupRef2} 
        position={[0, 2370, -400]}
      >
        {/* <axesHelper args={[500]} /> */}
        <TopFrontGlass {...props} />
        <TopFrontLeft {...props} />
        <TopFrontRight {...props} />
        <TopDiv {...props} />
      </group>
    </group>
  )
}

export function Side(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz } = props.int_length;
  if (props.current_side === 0) return null;
  switch (props.current_side) {
    case 1:
      return (
        <mesh
          position={[0, oy * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 2:
      return (
        <mesh
          position={[width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 3:
      return (
        <mesh
          position={[0, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry args={[width * unit, height2 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    case 4:
      return (
        <mesh
          position={[-width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        >
          <boxGeometry args={[depth * unit, height1 * unit, 1]} />
          <meshBasicMaterial color="#002853" transparent opacity={0.4} />
        </mesh>);
    default:
      return null;
  }
}

export function AddExtraColumns(props) {
  const { columns, columnFlag, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return props.activeStep === 0 && props.subStep === 1 && columns.adding === true ? (
    <group>
      {columnFlag[4] === true ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              if (columns.added[4] === false || columns.added[5] === false) props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              if (!columns.added[4]) props.ShiftColumn('E')
              else if (!columns.added[5]) props.ShiftColumn('F')
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[5] === false) props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[5] === false) props.ShiftColumn('F') }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[6] === false) props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[6] === false) props.ShiftColumn('G') }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { if (columns.added[7] === false) props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => { if (columns.added[7] === false) props.ShiftColumn('H') }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}

export function AddWalls(props) {
  const { walls, secondHeight } = props;
  const { width, depth } = props.length;
  const { unit, oy, oz } = props.int_length;

  return walls.adding === true ? (
    <group>
      {!secondHeight && walls.added[0] === false ?
        <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => {
              props.setCurrentSide(1);
            }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[0] = true;
              state.walls.adding = false;
            }}
          >
            Front Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight && walls.added[1] === false ?
        <Html scaleFactor={5} position={[-width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(2) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[1] = true;
              state.walls.adding = false;
            }}
          >
            Right Side<BsPlusCircle />
          </div>
        </Html> : null}
      {walls.added[2] === false ?
        <Html scaleFactor={5} position={[0, (depth - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(3) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[2] = true;
              state.walls.adding = false;
            }}
          >Back Side<BsPlusCircle />
          </div>
        </Html> : null}
      {!secondHeight && walls.added[3] === false ?
        <Html scaleFactor={5} position={[width * unit / 2, (depth / 2 - oy) * unit, -oz * unit]}>
          <div
            className="side-info"
            onPointerOver={(e) => { props.setCurrentSide(4) }}
            onPointerOut={(e) => props.setCurrentSide(0)}
            onClick={(e) => {
              props.setCurrentSide(0);
              state.walls.added[3] = true;
              state.walls.adding = false;
            }}
          >Left Side<BsPlusCircle />
          </div>
        </Html> : null}

    </group>
  ) : null

}


export function LengthInfo(props) {
  const { secondHeight } = props;
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, LineColor, LineEndColor, lengthEndLineWidth, lengthInfoDist } = props.int_length;
  const points = []
  points.push(new THREE.Vector3(0, 0, 0))
  points.push(new THREE.Vector3(width * unit, 0, 0))
  const lineGeometryForWidth = new THREE.BufferGeometry().setFromPoints(points)
  const points2 = [];
  points2.push(new THREE.Vector3(0, 0, 0))
  points2.push(new THREE.Vector3(0, depth * unit, 0))
  const lineGeometryForDepth = new THREE.BufferGeometry().setFromPoints(points2)
  const points3 = [];
  points3.push(new THREE.Vector3(0, 0, 0))
  points3.push(new THREE.Vector3(0, 0, height1 * unit));
  const lineGeometryForHeight1 = new THREE.BufferGeometry().setFromPoints(points3)
  const points4 = [];
  points4.push(new THREE.Vector3(0, 0, 0))
  points4.push(new THREE.Vector3(0, 0, height2 * unit));
  const lineGeometryForHeight2 = new THREE.BufferGeometry().setFromPoints(points4)

  const points5 = [];
  points5.push(new THREE.Vector3(0, 0, 0));
  points5.push(new THREE.Vector3(0, 0, lengthEndLineWidth * unit));
  const lineGeometryForEnd = new THREE.BufferGeometry().setFromPoints(points5);

  return props.activeStep === 0 && props.subStep === 0 ? (
    <group>
      <Html scaleFactor={5} position={[0, oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">{parseInt(width * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>

      <Html scaleFactor={5} position={[width * unit / 2, (oy - depth / 2) * unit, (-oz + 200) * unit]}>
        <div className="length-info">{parseInt(depth * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      <Html scaleFactor={5} position={[(width + 100) * unit / 2, oy * unit, (height1 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height1 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html>
      {secondHeight ? <Html scaleFactor={5} position={[width * unit / 2, (-depth + oy) * unit, (height2 / 2 - oz) * unit]}>
        <div className="length-info">{parseInt(height2 * props.length.unit + 0.5)} {props.length.unitName}</div>
      </Html> : null}

      <line geometry={lineGeometryForWidth} position={[-width * unit / 2, (oy + lengthInfoDist) * unit, -oz * unit + (height1) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForDepth} position={[width * unit / 2, (oy - depth) * unit, (-oz) * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight1} position={[width * unit / 2, (oy) * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForHeight2} position={[width * unit / 2, (oy - depth) * unit, -oz * unit]}>
        <lineBasicMaterial attach="material" color={LineColor} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[-width * unit / 2, (oy + lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthInfoDist) * unit, (-oz + height1 - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, oy * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy - depth) * unit, (-oz - lengthEndLineWidth / 2) * unit]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>


      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy + lengthEndLineWidth / 2) * unit, (-oz + height1) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>

      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy - depth + lengthEndLineWidth / 2) * unit, -oz * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>
      <line geometry={lineGeometryForEnd} position={[width * unit / 2, (oy - depth + lengthEndLineWidth / 2) * unit, (-oz + height2) * unit]} rotation={[Math.PI / 2, 0, 0]}>
        <lineBasicMaterial attach="material" color={LineEndColor} linewidth={1} linecap={"round"} linejoin={'round'} />
      </line>


    </group>
  ) : null;
}

export function ColumnLabels(props) {
  const { width, depth, height1, height2 } = props.length;
  const { unit, oy, oz, secondHeight } = props.int_length;
  const columns = props.columns;
  const columnFlag = props.columnFlag;

  const list = [];
  for (var i = 2; i < columns.added.length; i = i + 1) {
    if (columns.added[i]) {
      list.push(
        <Html key={i} scaleFactor={5} position={[width * unit / 2 - columns.pos[i] * unit, -oy * unit, (height1 - oz) * unit]}>
          <div className="length-info">{String.fromCharCode(65 + i)}</div>
        </Html>
      )
    }
  }

  return props.activeStep === 0 && props.subStep === 1 ? (
    <group>
      {columnFlag[0] ? <Html scaleFactor={5} position={[width * unit / 2 - columns.pos[0] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">A</div>
      </Html> : null}
      {columnFlag[1] ? <Html scaleFactor={5} position={[-width * unit / 2 + columns.pos[1] * unit, -oy * unit, (height1 - oz) * unit]}>
        <div className="length-info">B</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[-width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">C</div>
      </Html> : null}
      {secondHeight === false && columnFlag[2] === true ? <Html scaleFactor={5} position={[width * unit / 2, (depth - oy) * unit, (height2 - oz) * unit]}>
        <div className="length-info">D</div>
      </Html> : null}
      {list}
    </group>
  ) : null;

}

export function Walls(props) {
  const { walls } = props;
  const { width, depth, height2 } = props.length;
  const { int_depth, int_height2, unit, oy } = props.int_length;
  const dh2 = height2 - int_height2;

  return (
    <group position={[0, -250, (dh2 + wallTopHeight) * unit / 2]}>
      {/* <Wall width = {width} height = {height2} unit={unit} position={[0, (oy + 50) * unit + wallThickness * unit / 2, 0]} rotation = {[Math.PI / 2, 0, 0]}/>  */}
      {walls.added[2] ?
        <Wall width={width} height={height2} unit={unit} position={[0, (oy - depth) * unit + wallThickness * unit / 2, 0]} rotation={[Math.PI / 2, 0, 0]} ID={2}/>
        : null}
    </group>
  )
}


export function asciiDif(a, b) {
  return a.charCodeAt(0) - b.charCodeAt(0);
}

// function AddSubSystems(props) {
//   const { width, depth } = props.length;
//   const { unit, oy, oz } = props.int_length;
//   const { columns } = props;

//   const list = [];
//   const pos_list = [];

//   for (let i = 2; i < 24; i++) {
//     if (columns.added[i]) {
//       pos_list.push({ pos: columns.pos[i], id: i });
//     }
//   }
//   pos_list.push({ pos: columns.pos[0], id: 0 });
//   pos_list.push({ pos: width, id: 0 })
//   pos_list.sort(function (a, b) { return a.pos - b.pos });

//   for (let i = 0; i < pos_list.length - 1; i = i + 1) {
//     const swidth = pos_list[i + 1].pos - pos_list[i].pos;
//     const px = pos_list[i].pos;
//     const ID = pos_list[i].id;
//     list.push(
//       <Html key={i} scaleFactor={5} position={[width * unit / 2 - pos_list[i].pos * unit - swidth * unit / 2, oy * unit, -oz * unit]}>
//         <div
//           className="side-info side-info-subsystem"
//           onPointerOver={(e) => {
//             e.preventDefault();
//             props.setSelectedSide(1);
//             props.setCurrentWidth(swidth);
//             props.setCurrentPos(px + swidth / 2);
//           }}
//           onPointerOut={(e) => props.setSelectedSide(-1)}
//           onClick={(e) => {
//             if (props.systemID === 6 && columns.addedSubSystem[ID + 2] === true
//               && (columns.systemId[ID + 2] <= 3 || columns.systemId[ID + 2] === 5)) {
//               state.columns.addedForte[ID + 2] = true;
//               state.columns.fortePos[ID + 2] = default_forte_pos;
//             }
//             else {
//               state.columns.addedSubSystem[ID + 2] = true;
//               state.columns.systemId[ID + 2] = props.systemID;
//             }
//             state.columns.addingSubSystem = false;
//             props.setSelectedSide(-1);
//           }}
//         >
//           <BsPlusCircle />
//         </div>
//       </Html>

//     )
//   }

//   return (props.activeStep === 2 && columns.addingSubSystem === true) ? (
//     <group>
//       {/* <Html scaleFactor={5} position={[0, -oy * unit, -oz * unit]}>
//         <div
//           className="side-info"
//           onPointerOver={(e) => {
//             props.setSelectedSide(1);
//           }}
//           onPointerOut={(e) => props.setSelectedSide(-1)}
//           onClick={(e) => {
//             props.setSelectedSide(-1);
//             state.walls.added[0] = true;
//             state.walls.adding = false;
//           }}
//         >
//           Front Side<BsPlusCircle />
//         </div>
//       </Html> */}
//       <Html scaleFactor={5} position={[width * unit / 2, (oy - depth / 2) * unit, -oz * unit]}>
//         <div
//           className="side-info"
//           onPointerOver={(e) => { props.setSelectedSide(2) }}
//           onPointerOut={(e) => props.setSelectedSide(-1)}
//           onClick={(e) => {
//             e.preventDefault();
//             props.setSelectedSide(-1);
//             if (props.systemID === 6 && columns.addedSubSystem[1] === true && (columns.systemId[1] <= 3 || columns.systemId[1] === 5)) {
//               state.columns.addedForte[1] = true;
//               state.columns.fortePos[1] = default_forte_pos;
//             }
//             else {
//               state.columns.addedSubSystem[1] = true
//               state.columns.systemId[1] = props.systemID;
//             }
//             state.columns.addingSubSystem = false;
//           }}
//         >
//           Left Side<BsPlusCircle />
//         </div>
//       </Html>
//       {/* <Html scaleFactor={5} position={[0, (depth-oy) * unit, -oz * unit]}>
//         <div
//           className="side-info"
//           onPointerOver={(e) => {props.setSelectedSide(3)}}
//           onPointerOut={(e) => props.setSelectedSide(-1)}
//           onClick={(e) => {
//             props.setSelectedSide(-1);
//             state.walls.added[2] = true;
//             state.walls.adding = false;
//           }}
//         >Back Side<BsPlusCircle />
//         </div>
//       </Html> */}
//       <Html scaleFactor={5} position={[-width * unit / 2, (oy - depth / 2) * unit, -oz * unit]}>
//         <div
//           className="side-info"
//           onPointerOver={(e) => { props.setSelectedSide(4) }}
//           onPointerOut={(e) => props.setSelectedSide(-1)}
//           onClick={(e) => {
//             props.setSelectedSide(-1);
//             if (props.systemID === 6 && columns.addedSubSystem[0] === true && (columns.systemId[0] <= 3 || columns.systemId[0] === 5)) {
//               state.columns.addedForte[0] = true;
//               state.columns.fortePos[0] = default_forte_pos;
//             }
//             else {
//               state.columns.addedSubSystem[0] = true
//               state.columns.systemId[0] = props.systemID;
//             }
//             state.columns.addingSubSystem = false;
//           }}
//         >Right Side<BsPlusCircle />
//         </div>
//       </Html>
//       {list}
//     </group>
//   ) : null;

// }

// function SideForSubSystem(props) {
//   const { width, depth, height1, height2 } = props.length;
//   const { unit, oy, oz } = props.int_length;
//   const { currentPos, currentWidth } = props;
//   if (props.selectedSide === 0) return null;
//   switch (props.selectedSide) {
//     case 1:
//       return (
//         <mesh
//           position={[width * unit / 2 - currentPos * unit, oy * unit, (height1 / 2 - oz) * unit]}
//           rotation={[Math.PI / 2, 0, 0]}
//         >
//           <boxGeometry args={[currentWidth * unit, height1 * unit, 1]} />
//           <meshBasicMaterial color="#002853" transparent opacity={0.4} />
//         </mesh>);
//     case 2:
//       return (
//         <mesh
//           position={[width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
//           rotation={[Math.PI / 2, Math.PI / 2, 0]}
//         >
//           <boxGeometry args={[depth * unit, height1 * unit, 1]} />
//           <meshBasicMaterial color="#002853" transparent opacity={0.4} />
//         </mesh>);
//     case 3:
//       return (
//         <mesh
//           position={[0, (oy - depth) * unit, (height2 / 2 - oz) * unit]}
//           rotation={[Math.PI / 2, 0, 0]}
//         >
//           <boxGeometry args={[width * unit, height2 * unit, 1]} />
//           <meshBasicMaterial color="#002853" transparent opacity={0.4} />
//         </mesh>);
//     case 4:
//       return (
//         <mesh
//           position={[-width * unit / 2, (oy - depth / 2) * unit, (height1 / 2 - oz) * unit]}
//           rotation={[Math.PI / 2, Math.PI / 2, 0]}
//         >
//           <boxGeometry args={[depth * unit, height1 * unit, 1]} />
//           <meshBasicMaterial color="#002853" transparent opacity={0.4} />
//         </mesh>);
//     default:
//       return null;
//   }
// }


export function PoladN(props) {
  const modelID = props.modelID;
  const gltf = useLoader(GLTFLoader, poladModel, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
   })

  const { nodes, materials } = gltf;
  // const { nodes, materials } = useGLTF(poladModel)
  const snap = useSnapshot(state);
  // const [currentSide, setCurrentSide] = React.useState(0);
  // const [currentPos, setCurrentPos] = React.useState(-1);
  // const [selectedSide, setSelectedSide] = React.useState(0);
  // const [currentWidth, setCurrentWidth] = React.useState(0);

  // const ShiftColumn = (column) => {
  //   state.columns.editing = true;
  //   state.columns.editingColumn = column;
  //   state.columns.added[asciiDif(column, 'A')] = true;
  //   state.columns.adding = false;
  //   setCurrentSide(0);
  // }

  const rdepth = props.doubleSystem
    ? (snap.length.depth + lengths[modelID].column_thickness) / 2
    : snap.length.depth;

  const length = {
    ...snap.length,
    depth: props.doubleSystem ? rdepth : snap.length.depth,
  };

  // const { pos_x, pos_y, pos_z, scale_x, scale_y, scale_z, rot_x } = useControls({
  //   pos_x: 0,
  //   pos_y: -15,
  //   pos_z: 0,
  //   scale_x: 0,
  //   scale_y: 0,
  //   scale_z: 0,
  //   rot_x: -1.44,
  // });

  return (
    <group {...props} dispose={null} ref={props.group}>
      <group position={[0, 0, 0]} rotation={[0, 0, 0]} scale={0.01}>
        <TopDivBack
          xrepeat={1}
          yrepeat={1}
          nodes={nodes}
          materials={materials}
          length={length}
          int_length={lengths[modelID]}
          color={snap.isDesign ? snap.designStyle : snap.blades.color}
          isDesign={snap.isDesign}
          isTexture={snap.isTexture}
          texture={snap.blades.texture}
          opacity={snap.blades.opacity}
          modelID={modelID}
          origin={props.origin}
          doubleSystem={props.doubleSystem}
          systemVisible={snap.systemVisible}
          isLarge={false}
        />
      </group>
      <group position={[0, 0, 0]} rotation={[0, 0, 0]} scale={0.001}>
        <group scale={1000}>
        <group position={[0, 2.08, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001}>
          <WaterProfile
            nodes={nodes}
            materials={materials}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <BackProfile
            nodes={nodes}
            materials={materials}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <MiddleProfile
            nodes={nodes}
            materials={materials}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <LeftProfile
            nodes={nodes}
            materials={materials}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <RightProfile
            nodes={nodes}
            materials={materials}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnLeft
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnMiddleLeft
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnBackLeft
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnRight
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnMiddleRight
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <ColumnBackRight
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <TopFront
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
          <TopBackGlass
            nodes={nodes}
            materials={materials}
            columns={snap.columns}
            length={snap.length}
            int_length={lengths[modelID]}
            color={snap.isDesign ? snap.designStyle : snap.structure.color}
          />
        </group>
       {/*
        <Columns
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          color={snap.isDesign ? snap.designStyle : snap.structure.color}
          flag={snap.flag}
        />
        <Top
          nodes={nodes}
          materials={materials}
          length={snap.length}
          int_length={lengths[modelID]}
          glassColor={snap.isDesign ? snap.designStyle : snap.blades.color}
          coverColor={snap.isDesign ? snap.designStyle : snap.structure.color}
          isDesign={snap.isDesign}
          isTexture={snap.isBladeTexture}
          texture={snap.blades.texture}
          opacity={snap.blades.opacity}
          modelID={modelID}
          addedSubSystem={snap.columns.addedSubSystem}
          subSystemCount={snap.columns.subSystemCount}
        />
        <ColumnLabels
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
        />
        <LengthInfo
          secondHeight={secondHeight[modelID]}
          length={snap.length}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          modelID={modelID}
        />
        <AddExtraColumns
          columns={snap.columns}
          length={snap.length}
          int_length={lengths[modelID]}
          columnFlag={columnFlag[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          ShiftColumn={ShiftColumn}
          setCurrentSide={setCurrentSide}
        />
        <AddWalls
          walls={snap.walls}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setCurrentSide={setCurrentSide}
        />
        <Walls
          walls={snap.walls}
          length={snap.length}
          int_length={lengths[modelID]}
        />
        <Side
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          current_side={currentSide}
        />

        <AddSubSystems
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          secondHeight={secondHeight[modelID]}
          int_length={lengths[modelID]}
          activeStep={props.activeStep}
          subStep={props.subStep}
          setSelectedSide={setSelectedSide}
          setCurrentPos={setCurrentPos}
          setCurrentWidth={setCurrentWidth}
          systemID={snap.systemID}
        />
        <SideForSubSystem
          walls={snap.walls}
          columns={snap.columns}
          length={snap.length}
          columnFlag={columnFlag[modelID]}
          int_length={lengths[modelID]}
          selectedSide={selectedSide}
          currentPos={currentPos}
          currentWidth={currentWidth}
        />
        <SideInfills3
          length={snap.length}
          int_length={lengths[modelID]}
          columns={snap.columns}
          systemID={snap.systemID}
          dy = {100}
          dz = {-85}
          modelID={modelID}
        /> */}
        </group>
        {/* <PoladOrgin
          nodes={nodes}
          materials={materials}
        />
 */}

      </group>
    </group>
  )
}
