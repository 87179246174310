import React from "react";
import SubSystem from "../subsystems/SubSystem";

const rotations = [
  [Math.PI / 2, Math.PI, -Math.PI / 2],
  [Math.PI / 2, Math.PI, 0],
  [Math.PI / 2, Math.PI, Math.PI / 2],
  [Math.PI / 2, Math.PI, Math.PI],
];

function SideInfill(props) {
  const { side, columns, length, int_length } = props;
  const pos_list = [];
  const unit = int_length.unit;
  const bias1 = 0
  const mx = (length.width - int_length.column_thickness) / 2;
  const my = length.depth - int_length.column_thickness;
  const dx0 = -(int_length.column_thickness / 2 + (side === 1 ? -30 : 35)) * unit

  // const sw = (side === 1 || side === 3) ? 1.02 : 1
  // const sw1 = (side === 1 || side === 3) ? 1.015 : 1
  const sw = 1;
  const sw1 = 1;
  switch (side) {
    case 1:
      pos_list.push({
        pos: columns.pos[0] + int_length.column_thickness,
        id: 0,
      });
      pos_list.push({ pos: -columns.pos[1] + length.width, id: 1 });
      break;
    case 2:
      pos_list.push({
        pos: columns.pos[1] + int_length.column_thickness,
        id: 1,
      });
      pos_list.push({ pos: -columns.pos[2] + length.depth, id: 2 });
      break;
    case 3:
      pos_list.push({
        pos: columns.pos[2] + int_length.column_thickness,
        id: 2,
      });
      pos_list.push({ pos: -columns.pos[3] + length.width, id: 3 });
      break;
    case 4:
      pos_list.push({
        pos: columns.pos[3] + int_length.column_thickness,
        id: 3,
      });
      pos_list.push({ pos: -columns.pos[0] + length.depth, id: 0 });
      break;
    default:
      break;
  }
  const cnt = columns.pos.length;
  for (var index = 4; index < cnt; index = index + 1) {
    if (columns.side[index] === side && columns.added[index])
      pos_list.push({
        pos: columns.pos[index] + int_length.column_thickness / 2,
        id: index,
      });
  }
  pos_list.sort(function (a, b) {
    return a.pos - b.pos;
  });
  const list = [];
  const len = pos_list.length;

  const dw = (-(length.width - int_length.int_width) * unit) / 2;
  const dd = (length.depth - int_length.int_depth) * unit;
  const px1 = dw;
  const px2 = dw + length.width * unit;
  const py1 = dd;
  const py2 = dd - length.depth * unit;
  const bias = props.origin === false ? 24 : 0;

  var dx = 0;
  if (props.isLarge && props.doubleSystem) {
    if (props.origin && !props.systemVisible[0] && side === 2) dx = mx * unit;
    if (props.origin && !props.systemVisible[1] && side === 4) dx = -mx * unit;
    if (!props.origin && !props.systemVisible[2] && side === 2) dx = mx * unit;
    if (!props.origin && !props.systemVisible[3] && side === 4) dx = -mx * unit;
  }

  for (var i = 0; i < len - 1; i = i + 1) {
    const ID = pos_list[i].id + bias;
    if (columns.addedSubSystem[ID] === false) continue;
    var dy = 0;
    if (props.isLarge && props.doubleSystem) {
      if (props.origin && !props.systemVisible[0] && side === 1 && i === 1)
        dy = my * unit;
      if (props.origin && !props.systemVisible[1] && side === 1 && i === 0)
        dy = my * unit;
      if (!props.origin && !props.systemVisible[2] && side === 3 && i === 0)
        dy = -my * unit;
      if (!props.origin && !props.systemVisible[3] && side === 3 && i === 1)
        dy = -my * unit;
    }
    var dy0 = columns.systemId[ID] === 3 ? 0.5 : 0
    list.push(
      <SubSystem
        key={i}
        px={
          side === 1
            ? (px2 - pos_list[i].pos * unit + dx + dx0) - 4
            : side === 2
              ? px1 + dx + dx0 - 2
              : side === 3
                ? px1 + pos_list[i].pos * unit + dx + dx0 + 2
                : px2 + dx + dx0 + 4
        }
        py={
          side === 1
            ? py2 + dy
            : side === 2
              ? py2 + pos_list[i].pos * unit + dy
              : side === 3
                ? py1 + dy
                : py1 - pos_list[i].pos * unit + dy + dy0
        }
        pz={0}
        rotation={rotations[side - 1]}
        width={
          (pos_list[i + 1].pos - pos_list[i].pos - int_length.column_thickness) * sw
        }
        height={length.height1 - int_length.profile_thickness}
        systemID={columns.systemId[ID]}
        unit={props.unit}
        ID={ID}
        scale={1}
        type={1}
        side={side}
        modelID={props.modelID}
        systemType={1}
        isTexture={props.isTexture}
        texture={props.texture}
        texture1={props.texture1}
      />
    );
    if (columns.addedForte[ID] === true) {
      list.push(
        <SubSystem
          key={100 + i}
          px={
            side === 1
              ? px2 - pos_list[i].pos * unit + dx + dx0
              : side === 2
                ? px1 - 19 + dx
                : side === 3
                  ? px1 + pos_list[i].pos * unit + dx + dx0
                  : px2 + 1 + dx
          }
          py={
            side === 1
              ? py2 - 7 + dy
              : side === 2
                ? py2 + pos_list[i].pos * unit + dy + 1
                : side === 3
                  ? py1 + 7 + dy
                  : py1 - pos_list[i].pos * unit + dy
          }
          pz={0}
          rotation={rotations[side - 1]}
          width={
            (pos_list[i + 1].pos - pos_list[i].pos - int_length.column_thickness) * sw1
          }
          height={length.height1 - int_length.profile_thickness}
          systemID={6}
          unit={props.unit}
          ID={ID}
          scale={1}
          type={1}
          side={side}
          modelID={props.modelID}
          systemType={2}
          isTexture={props.isTexture}
          texture={props.texture}
          texture1={props.texture1}
        />
      );
    }
  }
  return list;
}

export default function SideInfills6(props) {
  const list = [];
  for (var i = 0; i < 4; i = i + 1) {
    if (props.doubleSystem === true) {
      if (props.origin === true && i === 2) continue;
      if (props.origin === false && i === 0) continue;
    }
    if (props.walls.added[i]) continue;
    list.push(
      <SideInfill
        key={i}
        side={i + 1}
        columns={props.columns}
        length={props.length}
        int_length={props.int_length}
        systemID={props.systemID}
        unit={props.int_length.unit}
        isTexture={props.isTexture}
        texture={props.texture}
        texture1={props.texture1}
        doubleSystem={props.doubleSystem}
        origin={props.origin}
        systemVisible={props.systemVisible}
        isLarge={props.isLarge}
        walls={props.walls}
        modelID={props.modelID}
      />
    );
  }
  return list;
}
