/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useSnapshot } from "valtio"
import { useGLTF, useHelper, useTexture } from "@react-three/drei";
import * as THREE from "three";
import state from "../../state"
import panora_parts from "../../assets/models/panora_parts.glb";
import { TopDepthDivCount, TopDivCount } from "../DivCount";
// import { useControls } from "leva";
import { calcDivConfig } from "./helpers";
import { useRef } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useFrame } from "react-three-fiber";
import { useState } from "react";
import { BoxHelper } from "three";
// import { mergeBufferGeometries } from "three-stdlib";

export function Glass(props) {
  const { nodes } = useGLTF(panora_parts);
  const { opacity } = props;
  const { width, depth, height1 } = props.length;
  const {
    int_width,
    int_depth,
    top_glass_width,
    mid_length_limit,
    unit,
    column_width,
    column_thickness,
  } = props.int_length;
  // const texture = useTexture(props.texture);
  // texture.wrapS = THREE.RepeatWrapping;
  // texture.wrapT = THREE.RepeatWrapping;
  // texture.repeat.set(1, 1);
  const dd = depth - int_depth;
  const sd = depth / int_depth;
  // var div_count = parseInt(
  //   (width <= mid_length_limit[0] ? width : width / 2) / top_glass_width
  // );
  // div_count =
  //   width <= mid_length_limit[0]
  //     ? TopDivCount(width, props.modelID)
  //     : TopDivCount(width / 2, props.modelID);

  // const div_width =
  //   (width <= mid_length_limit[0] ? width : width / 2) / div_count;

  const { div_count, div_width } = calcDivConfig(props);

  const px = 32;
  const py = height1 * unit - 13;
  const pz = props.doubleSystem ? (props.origin ? -70 : -78) : -75;
  console.log("props.systemVisible", props.systemVisible);
  const list = [];
  const sw = (div_width - 10) / top_glass_width;
  const scale = [sw + 0.2, sd * 1.04, 1];
  const scaleR = [sw + 0.2, sd * 1, 1];
  const scale2 = [sw, sd * 2.1, 1];
  const scaleR2 = [sw, sd * 1.85, 1];

  const rotation = [-Math.PI / 2, 0, 0];
  const depth_div_count = TopDepthDivCount(depth, props.modelID);

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;
  var cnt = width <= mid_length_limit[0] ? 1 : 2;
  cnt =
    props.isLarge &&
    props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[0] || !props.systemVisible[1])
      ? 1
      : cnt;
  cnt =
    props.isLarge &&
    !props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[2] || !props.systemVisible[3])
      ? 1
      : cnt;

  var isLong = true;
  for (let i = 0; i < div_count; i = i + 1) {
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    const isMiddle = cnt > 1 && i === div_count;
    list.push(
      <group
        key={i}
        position={[
          ((-(width - int_width) * unit) / 2 +
            div_width * i * unit +
            dx * unit +
            (isMiddle ? 10 : 0)) / 10,
          0,
          0,
        ]}
        scale={[
          // cnt > 1 && (i === div_count - 1 || i === div_count) ? 0.9 : 1,
          1,
          1,
          1,
        ]}
      >
        {depth_div_count > 1 ? (
          <>
            {props.origin ? (
              <>
                <mesh
                  geometry={nodes.glass1.geometry}
                  // position={[px, py, pz - (!isLong || i >= div_count ? 10 : 0)]}
                  position={[0, 0, 0]}
                  rotation={rotation}
                  scale={isLong ? scale : scaleR}
                  material-color={'#e87a13'}
                >
                  {/* <meshPhongMaterial opacity={opacity} transparent /> */}
                </mesh>
                <mesh
                  geometry={nodes.glass1.geometry}
                  // position={[px, py, pz - (depth * unit) / 2]}
                  position={[0, 0, 0]}
                  rotation={rotation}
                  scale={scale}
                  material-color={'#e87a13'}
                >
                  {/* <meshPhongMaterial opacity={opacity} transparent /> */}
                </mesh>
              </>
            ) : (
              <>
                <mesh
                  geometry={nodes.glass1.geometry}
                  // position={[px, py, pz]}
                  position={[0, 0, 0]}
                  rotation={rotation}
                  scale={scale}
                  material-color={'#e87a13'}
                >
                  {/* <meshPhongMaterial opacity={opacity} transparent /> */}
                </mesh>
                <mesh
                  geometry={nodes.glass1.geometry}
                  // position={[
                  //   px,
                  //   py,
                  //   pz -
                  //     (depth * unit) / 2 -
                  //     (!isLong || i >= div_count ? -12 : 0),
                  // ]}
                  position={[0, 0, 0 - ((depth - 1403.7530517578125) / 10 / 2)]}
                  rotation={rotation}
                  scale={isLong ? scale : scaleR}
                  material-color={'#e87a13'}
                >
                  {/* <meshPhongMaterial opacity={opacity} transparent /> */}
                </mesh>
              </>
            )}
          </>
        ) : (
          <mesh
            geometry={nodes.glass1.geometry}
            // position={[
            //   px,
            //   py,
            //   pz - (depth * unit) / 4 + (!isLong && !props.origin ? 3 : 0),
            // ]}
            position={[0, 0, 0]}
            rotation={rotation}
            scale={isLong ? scale2 : scaleR2}
            material-color={'#e87a13'}
          >
            <meshPhongMaterial opacity={opacity} transparent />
          </mesh>
        )}
      </group>
    );
  }

  console.log("list", list)
  return (
    <group
      position={[0, 0, 0]}
      // position={[
      //   ((div_width - top_glass_width) * unit) / 2 - 6,
      //   0,
      //   (dd * unit * 3) / 4,
      // ]}
    >
      {list}
    </group>
  );
}

export function Divider(props) {
  const xrepeat = 0.1,
    yrepeat = 0.1;
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, visible, useScale } = props;
  const { width, depth, height1 } = props.length;

  const {
    int_width,
    int_depth,
    int_height1,
    top_div_length,
    top_glass_width,
    unit,
    mid_length_limit,
    top_div_width,
    column_thickness,
  } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length;
  const sd = (depth - delta) / top_div_length;
  const list = [];

  const { div_count, div_width } = calcDivConfig(props);

  const px = 32;
  const py = 236.52;
  const pz = (-int_depth * unit) / 2 - 0.5;
  const y_scale = useScale ? sd : 1;
  const scale = [1.3, y_scale, 1];
  const scaleR = [1.3, y_scale * 0.97, 1];
  const angle = props.doubleSystem ? -Math.atan(100 / depth) : 0;
  const rotation = [-Math.PI / 2, 0, 0];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? div_count
      : 1;
  var isLong = true;

  const light_geometry = new THREE.BoxGeometry(200, 100, 2000);
  const light_material = new THREE.MeshPhysicalMaterial({ color: "#ffffff" });

  const y_pos = -315.5;
  const z_pos = -150;
  const x_pos = 2500;

  // const depth_div_count = TopDepthDivCount(depth, props.modelID);

  // can move to loop by changing the value of i e.g for (var i = 0; i <= div_count; i = i + 1)
  list.push(
    <group
      key={-1}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * 0 * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
    </group>
  );
  list.push(
    <group
      key={-2}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * div_count * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        // position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
        position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
    </group>
  );

  for (var i = dx; i < div_count; i = i + 1) {
    if (width > mid_length_limit[0] && i === div_count) continue;
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    list.push(
      <group
        key={i}
        // position={[(-(width - int_width) * unit) / 2 - 40 + div_width * i * unit / 10, 0, 0]}
        position={[
          y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * i * unit) / 10,
          z_pos,
          x_pos,
        ]}
      >
        <mesh
          castShadow
          geometry={nodes.dividerprofile.geometry}
          material={materials.All_profiles}
          // position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          scale={isLong ? scale : scaleR}
          rotation={rotation}
          material-color={color}
        />
        {visible ? (
          <>
            <mesh
              key={i}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) - 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
            <mesh
              key={i + div_count}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) + 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
          </>
        ) : null}
      </group>
    );
  }

  return (
  <group
    // position={[0, 0, (dd * unit/ 100) / 2]}
    position={[0, 0, 0]}
  >
    <mesh
      castShadow
      geometry={nodes.centerprofile.geometry}
      material={props.isTexture ? material_texture : materials.All_profiles}
      // position={[px, py, pz]}
      position={[0, 90, -60]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={[(width / 3708.763885498047) - 0.075, 1, 2]}
      material-color={color}
    />
    {list}
  </group>
  );
}

export function CenterProfile(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, xrepeat, yrepeat } = props;
  const { width, depth, height1 } = props.length;
  const { int_width, int_depth, unit, column_thickness } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const dd = depth - int_depth;
  const px = (int_width * unit) / 2 - 6;
  const py = height1 * unit - 11.5;
  const pz = (-depth * unit) / 2;
  var sc = 1;
  sc =
    props.isLarge &&
    props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[0] || !props.systemVisible[1])
      ? 0.5
      : sc;
  sc =
    props.isLarge &&
    !props.origin &&
    props.doubleSystem &&
    (!props.systemVisible[2] || !props.systemVisible[3])
      ? 0.5
      : sc;
  const scale = [(width / 3708.763885498047) - 0.075, 1, 2];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const div_count = TopDepthDivCount(depth, props.modelID);

  const dx1 =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;
  const dx2 =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[1]) ||
      (!props.origin && !props.systemVisible[3]))
      ? (width + column_thickness) / 2 - column_thickness
      : 0;

  return (
    <group
      {...props}
      position={[(dx1 * unit) / 2 - (dx2 * unit) / 2, 100, 0]}
    >
      <mesh
        castShadow
        geometry={nodes.centerprofile.geometry}
        material={props.isTexture ? material_texture : materials.All_profiles}
        // position={[px, py, pz]}
        position={[0, -8.7, pz/10 + 140]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={scale}
        material-color={color}
      />
    </group>
  );
}

export function TopPartStatic(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const snap = useSnapshot(state);
  const { length, modelID, int_length } = props;

  return (
    <group scale={1} position={[0, 0, -500]}>
      {/* <Glass
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.blades.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.blades.texture}
        opacity={snap.blades.opacity}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      /> */}

      <CenterProfile
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.structure.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      />

      <Divider
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.structure.color}
        visible={snap.hasLight}
        bladeRotation={snap.blades.rotation}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
        useScale={false}
      />
    </group>
  )
}

export function TopPartVertical(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const snap = useSnapshot(state);
  const { length, modelID, int_length } = props;

  return (
    <group scale={1} position={[0, 0, -500]} rotation={[Math.PI / 2, 0, 0]}>
      {/* <Glass
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.blades.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.blades.texture}
        opacity={snap.blades.opacity}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      /> */}

      <CenterProfile
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.structure.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      />

      <Divider
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.structure.color}
        visible={snap.hasLight}
        bladeRotation={snap.blades.rotation}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
        useScale={false}
      />
    </group>
  )
}

export function TopPart(props) {
  const { nodes, materials } = useGLTF(panora_parts);
  const snap = useSnapshot(state);
  const { length, modelID, int_length } = props;
  const rotation = [0.35, 0, 0];

  return (
    <group scale={1} position={[0, 40, 100]} rotation={rotation}>
      {/* <Glass
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.blades.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.blades.texture}
        opacity={snap.blades.opacity}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      /> */}

      {/* <CenterProfile
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.isDesign ? snap.designStyle : snap.structure.color}
        isDesign={snap.isDesign}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
      /> */}

      <Divider
        nodes={nodes}
        materials={materials}
        length={length}
        int_length={int_length}
        color={snap.structure.color}
        visible={snap.hasLight}
        bladeRotation={snap.blades.rotation}
        isTexture={snap.isTexture}
        texture={snap.structure.texture}
        opacity={snap.blades.opacity}
        xrepeat={1}
        yrepeat={1}
        modelID={modelID}
        origin={props.origin}
        doubleSystem={props.doubleSystem}
        systemVisible={snap.systemVisible}
        isLarge={false}
        useScale={false}
      />
    </group>
  )
}

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { int_height1, int_height2, thickness, column_depth } = props.int_length;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - column_depth / 2));
  const alpha2 = Math.atan(Math.abs(int_height2 - int_height1 - thickness) / (depth - column_depth / 2));

  if (height1 > height2 - thickness) return - (alpha1 + alpha2);
  return alpha1 - alpha2;
}

export function TopDivFront(props) {
  // const { pos_x, pos_y, pos_z, scale_x, scale_y, scale_z, Ucolor } = useControls({
  //   pos_x: 0,
  //   pos_y: 0,
  //   pos_z: 0,
  //   scale_x: 2000,
  //   scale_y: 0.1,
  //   scale_z: 0.1,
  //   Ucolor: '#fff',
  // });
  const xrepeat = 0.1,
    yrepeat = 0.1;
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, visible, useScale } = props;
  const { width, depth, height1 } = props.length;

  const {
    int_width,
    int_depth,
    int_height1,
    top_div_length,
    top_glass_width,
    unit,
    mid_length_limit,
    top_div_width,
    column_thickness,
  } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  // const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length;
  // const sd = (depth - delta) / top_div_length;
  const sd = (depth - 1200 - (170 * 2)) / 2811.9752502441406;
  const sd_tri = (depth - 1200 - (170 * 2)) / 2723.0959701538086;
  // const dd2 = (depth - 1200 - (170 * 2)) - 2811.9752502441406;
  const dd2 = depth - 2811.9752502441406;
  const list = [];

  const alpha = CalcRotation(props);

  const { div_count, div_width } = calcDivConfig(props);

  const px = 32;
  const py = 236.52;
  const pz = (-int_depth * unit) / 2 - 0.5;
  const y_scale = useScale ? sd : 1;
  const sda = (depth - 1200 - (170 * 2)) / 2811.9752502441406;
  const angle = Math.atan(sda);


  const scale = [1, sd, 1];
  const scaleR = [1, sd * 0.97, 1];
  // const angle = props.doubleSystem ? -Math.atan(100 / depth) : 0;
  const rotation = [Math.PI /2, 0, 0];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? div_count
      : 1;
  var isLong = true;

  const light_geometry = new THREE.BoxGeometry(200, 100, 2000);
  const light_material = new THREE.MeshPhysicalMaterial({ color: "#ffffff" });

  const y_pos = -315.5;
  const z_pos = -150;
  const x_pos = 2500;

  const groupRef = useRef(null);
  const meshFrontRef = useRef(null);
  const meshBackRef = useRef(null);

  // useHelper(groupRef, BoxHelper, "blue");

  // let geometries = [];
  // const div_front = nodes.dividerprofile.geometry.clone();
  // div_front.translate(0, 0, 0);
  // div_front.scale(1, 0.5, 1)
  // div_front.rotateX(-2.85)
  // geometries.push(div_front);
  // const div_back = nodes.dividerprofile.geometry.clone();
  // div_back.translate(0, 127, 65)
  // div_back.scale(1, 0.95, 1)
  // div_back.rotateY(Math.PI);
  // div_back.rotateX(2.01);
  // geometries.push(div_back);
  
  // merge
  // const compactGeom = mergeBufferGeometries(geometries);
  // compactGeom.computeBoundingBox();

  // console.log("compactGeom", compactGeom)

  
  // can move to loop by changing the value of i e.g for (var i = 0; i <= div_count; i = i + 1)
  list.push(
    <group
      key={-1}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * 0 * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        ref={meshFrontRef}
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        position={[px, py, pz + (false && !isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
      {/* <mesh
        castShadow
        geometry={compactGeom}
        material={materials.All_profiles}
        position={[px, py, pz + (false && !isLong && !props.origin ? 3 : 0)]}
        scale={[1, 1, sd_tri]}
        rotation={[0, 0, 0]}
        material-color={color}
      /> */}
    </group>
  );
  list.push(
    <group
      key={-2}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * div_count * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        // position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
        position={[px, py, pz + (false && !isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
    </group>
  );

  for (var i = dx; i < div_count; i = i + 1) {
    if (width > mid_length_limit[0] && i === div_count) continue;
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    list.push(
      <group
        key={i}
        // position={[(-(width - int_width) * unit) / 2 - 40 + div_width * i * unit / 10, 0, 0]}
        position={[
          y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * i * unit) / 10,
          z_pos,
          x_pos,
        ]}
      >
        <mesh
          castShadow
          geometry={nodes.dividerprofile.geometry}
          material={materials.All_profiles}
          // position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          scale={isLong ? scale : scaleR}
          rotation={rotation}
          material-color={color}
        />
        {visible ? (
          <>
            <mesh
              key={i}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) - 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
            <mesh
              key={i + div_count}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) + 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
          </>
        ) : null}
      </group>
    );
  }

  const basePos = [0, 40 + 0, - 50  -(depth - 9000) * 0.1 / 2];
  const triPos = [0, 48 + 0, -(depth - 9000) * 0.095 - 376.00 - 0];

  return (
  <group
    ref={groupRef}
    // position={[0, 40 + pos_z, - 50  -(depth - 9000) * 0.1 / 2]}
    position={basePos}
    // scale={[1, 1, sd]}
  >
    <axesHelper args={[50]} />
    {list}
  </group>
  );
}

export function TopDivBack(props) {
  const xrepeat = 0.1,
    yrepeat = 0.1;
  const { nodes, materials } = useGLTF(panora_parts);
  const { color, visible, useScale } = props;
  const { width, depth, height1 } = props.length;

  const {
    int_width,
    int_depth,
    int_height1,
    top_div_length,
    top_glass_width,
    unit,
    mid_length_limit,
    top_div_width,
    column_thickness,
  } = props.int_length;
  const texture = useTexture(props.texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  texture.rotation = Math.PI / 2;
  // const dd = depth - int_depth;
  const dh1 = height1 - int_height1;
  const delta = int_depth - top_div_length;
  // const sd = (depth - delta) / top_div_length;
  const sd = (depth - 1200 - (170 * 2)) / 2811.9752502441406;
  const sd_tri = (depth - 1200 - (170 * 2)) / 2723.0959701538086;
  // const dd2 = (depth - 1200 - (170 * 2)) - 2811.9752502441406;
  const dd2 = depth - 2811.9752502441406;
  const list = [];

  const alpha = CalcRotation(props);

  const { div_count, div_width } = calcDivConfig(props);

  const px = 32;
  const py = 236.52;
  const pz = (-int_depth * unit) / 2 - 0.5;
  const y_scale = useScale ? sd : 1;
  const sda = (depth - 1200 - (170 * 2)) / 2811.9752502441406;
  const angle = Math.atan(sda);


  const scale = [1, 1000 / 2811.9752502441406, 1];
  const scaleR = [1, 1 * 0.97, 1];
  // const angle = props.doubleSystem ? -Math.atan(100 / depth) : 0;
  const rotation = [ -1.598722, 0, 0];
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const dx =
    props.isLarge &&
    props.doubleSystem &&
    ((props.origin && !props.systemVisible[0]) ||
      (!props.origin && !props.systemVisible[2]))
      ? div_count
      : 1;
  var isLong = true;

  const light_geometry = new THREE.BoxGeometry(200, 100, 2000);
  const light_material = new THREE.MeshPhysicalMaterial({ color: "#ffffff" });

  const y_pos = -315.5;
  const z_pos = 54.9;
  const x_pos = 2373;
  
  // can move to loop by changing the value of i e.g for (var i = 0; i <= div_count; i = i + 1)
  list.push(
    <group
      key={-1}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * 0 * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        position={[px, py, pz + (false && !isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
    </group>
  );
  list.push(
    <group
      key={-2}
      position={[
        y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * div_count * unit) / 10,
        z_pos,
        x_pos,
      ]}
    >
      <mesh
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        position={[px, py, pz + (false && !isLong && !props.origin ? 3 : 0)]}
        scale={isLong ? scale : scaleR}
        rotation={rotation}
        material-color={color}
      />
    </group>
  );

  for (var i = dx; i < div_count; i = i + 1) {
    if (width > mid_length_limit[0] && i === div_count) continue;
    isLong = true;
    if (props.doubleSystem) {
      if (i < div_count) {
        if (props.origin) isLong = !props.systemVisible[2];
        else isLong = !props.systemVisible[0];
      } else {
        if (props.origin) isLong = !props.systemVisible[3];
        else isLong = !props.systemVisible[1];
      }
    }
    list.push(
      <group
        key={i}
        position={[
          y_pos + ((-(width - int_width) * unit) / 2 - 40 + div_width * i * unit) / 10,
          z_pos,
          x_pos,
        ]}
      >
        <mesh
          castShadow
          geometry={nodes.dividerprofile.geometry}
          material={materials.All_profiles}
          // position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          position={[px, py, pz + (!isLong && !props.origin ? 3 : 0)]}
          scale={isLong ? scale : scaleR}
          rotation={rotation}
          material-color={color}
        />
        {visible ? (
          <>
            <mesh
              key={i}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) - 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
            <mesh
              key={i + div_count}
              geometry={light_geometry}
              material={light_material}
              position={[
                px,
                py - 7,
                pz + (!isLong && !props.origin ? 3 : 0) + 50,
              ]}
              rotation={[0, 0, 0]}
              scale={0.01}
            />
          </>
        ) : null}
      </group>
    );
  }

  const basePos = [0, dh1 * 0.1, -(depth - int_depth) * 0.1];

  return (
  <group
    position={basePos}
  >
    {list}
  </group>
  );
}

export function GlassShader({ color, scale, depth }) {
  const { nodes, materials} = useGLTF(panora_parts);
  const [originalBounds, setOriginalBounds] = useState(null)
  const sd = (depth) / 2811.9752502441406;

  const meshRef = useRef(null);

  useEffect(() => {
    if (meshRef.current) {
      // Compute the bounding box on first render
      const bbox = meshRef.current.geometry.boundingBox.clone()
      setOriginalBounds(bbox)
    }
  }, []);

  useEffect(() => {
    if (meshRef.current && originalBounds) {
      // Scale only in the x-direction
      meshRef.current.scale.set(1, sd, 1)


      // Calculate the offset to keep the left edge in place
      const originalWidth = originalBounds.max.y - originalBounds.min.y
      const newWidth = originalWidth * sd
      const offset = (newWidth - originalWidth) / 2

      // Apply the offset to keep the left edge in place
      meshRef.current.position.z = offset
      // console.log("bbox", originalBounds, meshRef.current)
      // console.log("bboxc", meshRef.current.position, originalWidth, newWidth, offset)
    }
  }, [sd, originalBounds])

  return (
  <group
    position={[0, 100, 300]}
    scale={[1, 1, 1]}
  >
    <group rotation={[0.35, 0, 0]}>
      <axesHelper args={[50]} />
      <mesh
        ref={meshRef}
        castShadow
        geometry={nodes.dividerprofile.geometry}
        material={materials.All_profiles}
        // position={[px, py, pz]}
        // position={[0, 90, -60]}
        rotation={[-Math.PI / 2, 0, 0]}
        // scale={[1, 1, 2]}
        material-color={color}
      />
    </group>
  </group>
  );
}

// useGLTF.preload('models/panora_parts.glb')
