import React, { useState, useContext, useEffect } from "react";
import { useSnapshot } from "valtio";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";

import state from "../../state";
import CustomInput from "./CustomInput";
import AppContext from "../../context/AppContext";
import { lengths, secondHeight, subsystem_lengths } from "../../utils/constant";
import { ReactComponent as RepeatIcon } from "../../assets/img/svg/repeat.svg";
import { minAngle, maxAngle } from "../../utils/constant";
import OverForte from "common/OverForte";
import { useTranslation } from "react-i18next";

const Dimension = () => {
  const snap = useSnapshot(state);
  const { modelID, systemID } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery("(max-width:769px)");

  const unit = snap.length.unit;
  const minW = snap.isModel
    ? lengths[modelID].min_width
    : subsystem_lengths[systemID].min_width;
  const maxW = snap.isModel
    ? lengths[modelID].max_width
    : subsystem_lengths[systemID].max_width;
  const minD = snap.isModel
    ? lengths[modelID].min_depth
    : subsystem_lengths[systemID].min_height;
  const maxD = snap.isModel
    ? lengths[modelID].max_depth
    : subsystem_lengths[systemID].max_height;
  const minH1 = lengths[modelID].min_height1;
  const maxH1 = lengths[modelID].max_height1;
  const minH2 = lengths[modelID].min_height2;
  const maxH2 = lengths[modelID].max_height2;

  const [width, setWidth] = useState(snap.length.width);
  const [depth, setDepth] = useState(snap.length.depth);
  const [height1, setHeight1] = useState(snap.length.height1);
  const [height2, setHeight2] = useState(snap.length.height2);
  const length_unit = parseInt(1 / snap.length.unit);
  var diff = 500;
  if (modelID === 9 || modelID === 20) diff = 800;
  if (modelID === 10 || modelID === 6) {
    if (depth <= 3000) diff = 700;
    else diff = 800;
  }
  if (modelID === 11 || modelID === 16) {
    if (depth <= 6500) diff = 850;
    else diff = 1350;
  }

  const isLanai =
    snap.isModel &&
    (modelID === 4 ||
      modelID === 1 ||
      modelID === 13 ||
      modelID === 15 ||
      modelID === 14 ||
      modelID === 19 ||
      modelID === 17);

  const calcAngle = (depth, height1, height2) => {
    return (Math.atan((height2 - height1) / depth) * 180) / Math.PI;
  };

  const isValid = (depth, height1, height2) => {
    if (!minAngle[modelID]) return true;
    const alpha = calcAngle(depth, height1, height2);
    if (alpha < minAngle[modelID] || alpha > maxAngle[modelID]) return false;
    return true;
  };

  const getBackHeight = (depth, height1, height2) => {
    const alpha = calcAngle(depth, height1, height2);
    if (alpha < minAngle[modelID])
      return parseInt(
        depth * Math.tan((minAngle[modelID] / 180) * Math.PI) + height1
      );
    return parseInt(
      depth * Math.tan((maxAngle[modelID] / 180) * Math.PI) + height1
    );
  };

  const handleChangeTop = () => {
    if (snap.length.width * snap.length.depth < 50000000)
      state.columns.systemDirection[23] = 0;
    else state.columns.systemDirection[23] = 1;
  };

  const resetSubsystemWidth = () => {
    for (let u = 0; u < state.subSystemWidth.length; u = u + 1) {
      state.subSystemWidth[u] = 0;
    }
    return true;
  };

  useEffect(() => {
    if (modelID == 15 && snap.length.width == 4040) {
      addColumns(snap.length.width);
    }
    if (modelID == 15 && depth < minD) {
      setDepth(snap.length.depth);
    }
    if ((modelID == 17) && snap.length.width != lengths[modelID].default_width) {
      addColumns(snap.length.width);
    }
    if (modelID == 20 || modelID == 19 || modelID == 24) {
      addColumns(snap.length.width);
      setWidth(snap.length.width);
      setDepth(snap.length.depth);
    }
  }, [modelID, snap.length.width, snap.length.depth, setWidth, setDepth]);

  // useEffect(() => {
  //   addColumns(snap.length.width);
  // }, [snap.length.width]);

  const addColumns = (newVal) => {
    if (newVal > lengths[modelID].mid_length_limit[0]) {
      if (secondHeight[modelID]) {
        state.columns.isShift[2] = true;
        state.columns.pos[2] = newVal / 2;
        state.columns.added[2] = true;
        // if (modelID > 0 && (modelID < 9 || modelID === 10 || modelID === 13 || modelID === 15 || modelID === 14)) state.columns.addedSubSystem[4] = true
        //xingod code
        if (
          modelID > 0 &&
          (modelID === 3 ||
            modelID === 6 ||
            modelID === 5 ||
            modelID === 7 ||
            modelID === 10)
        ) {
          state.columns.addedSubSystem[4] = true;
        }
        // if (modelID === 16 && state.columns.addedSubSystem[2]) state.columns.addedSubSystem[4] = true
        //
      } else {
        state.columns.isShift[4] = true;
        state.columns.pos[4] = newVal / 2;
        state.columns.side[4] = 3;
        state.columns.added[4] = true;
        state.columns.isShift[6] = true;
        state.columns.pos[6] = newVal / 2;
        state.columns.added[6] = true;
        state.columns.side[6] = 1;
        //xingod code
        if (modelID > 0 && (modelID === 2 || modelID === 8)) {
          for (var i = secondHeight[modelID] ? 2 : 4; i < 18; i = i + 1) {
            state.columns.addedSubSystem[i + 2] = true;
          }
        }
        //
      }
    }
    if (newVal <= lengths[modelID].mid_length_limit[0]) {
      for (var i = secondHeight[modelID] ? 2 : 4; i < 18; i = i + 1) {
        state.columns.isShift[i] = false;
        state.columns.pos[i] = 0;
        state.columns.added[i] = false;
        if (
          modelID > 0 &&
          modelID !== 6 && // prevent wrong grande left/right subsystem count
          (modelID < 9 ||
            modelID === 10 ||
            modelID === 13 ||
            modelID === 15 ||
            modelID === 14)
        ) {
          // state.columns.addedSubSystem[i + 2] = false; //xingod
          state.columns.deletedSubSystem[i + 2] = false;
        }
      }
      state.columns.count = 0;
    }
    if (newVal > lengths[modelID].mid_length_limit[1]) {
      if (secondHeight[modelID]) {
        state.columns.added[3] = true;
        state.columns.isShift[3] = true;
        state.columns.pos[3] =
          (newVal / 3) * 2 - lengths[modelID].column_width / 6;
        state.columns.pos[2] = newVal / 3 + lengths[modelID].column_width / 6;
        state.columns.count = 2;
        // if (modelID > 0 && (modelID < 9 || modelID === 10 || modelID === 13 || modelID === 15 || modelID === 14)) state.columns.addedSubSystem[5] = true
        if (
          modelID > 0 &&
          (modelID === 3 ||
            modelID === 6 ||
            modelID === 5 ||
            modelID === 7 ||
            modelID === 10)
        ) {
          state.columns.addedSubSystem[5] = true;
        }
        // if (modelID > 0 && (modelID === 2 || modelID === 8)) state.columns.addedSubSystem[5] = true
      } else {
        state.columns.added[5] = true;
        state.columns.isShift[5] = true;
        state.columns.pos[5] =
          (newVal / 3) * 2 - lengths[modelID].column_width / 6;
        state.columns.side[5] = 1;
        state.columns.side[4] = 1;
        state.columns.pos[4] = newVal / 3 + lengths[modelID].column_width / 6;
        state.columns.added[7] = true;
        state.columns.isShift[7] = true;
        state.columns.pos[7] =
          (newVal / 3) * 2 - lengths[modelID].column_width / 6;
        state.columns.pos[6] = newVal / 3 + lengths[modelID].column_width / 6;
        state.columns.side[7] = 3;
        state.columns.side[6] = 3;
      }
    }
    // if (newVal <= lengths[modelID].mid_length_limit[1]) {
    if (
      newVal <= lengths[modelID].mid_length_limit[1] &&
      (modelID !== 2 || modelID !== 8)
    ) {
      if (modelID == 15) {
        state.columns.added[5] = false;
      }
      for (var j = secondHeight[modelID] ? 3 : 7; j < 18; j = j + 1) {
        state.columns.isShift[j] = false;
        state.columns.pos[j] = 0;
        state.columns.added[j] = false;
        if (
          modelID > 0 &&
          modelID !== 6 && // prevent wrong grande left/right subsystem count
          (modelID < 9 ||
            modelID === 10 ||
            modelID === 13 ||
            modelID === 15 ||
            modelID === 14)
        ) {
          state.columns.addedSubSystem[j + 2] = false;
        }
        if (
          modelID > 0 &&
          (modelID < 9 ||
            modelID === 10 ||
            modelID === 13 ||
            modelID === 15 ||
            modelID === 14)
        )
          state.columns.deletedSubSystem[j + 2] = false;
      }
      state.columns.count = 1;
    }
  };

  const handleWidthSliderChange = (event, newVal) => {
    if (!validationVal("width", minW, maxW, newVal)) return false;
    if (
      !snap.isModel &&
      systemID === 0 &&
      newVal * snap.length.depth > 12000000
    )
      return false;
    let _minW = minW;
    if ([15].includes(modelID)) {
      if (newVal < 3500) {
        _minW = minW;
      } else {
        _minW = 3500;
      }
    } 
    // else if (![19, 17, 4, 13, 14].includes(modelID) && newVal > 6040) {
    //   _minW = 6240;
    // } 
    else if ([19, 4, 14].includes(modelID) && newVal < 6310) {
      _minW = minW;
    }
    else if ([19, 4, 14].includes(modelID) && newVal > 6040) {
      _minW = 6310;
    }
    //CABBANA
    else if ([17].includes(modelID) && newVal < 7110) {
      _minW = minW;
    }
    else if ([17].includes(modelID) && newVal > 7040) {
      _minW = 7310;
    }
    //CABBANA X
    else if ([13].includes(modelID) && newVal < 6440) {
      _minW = minW;
    }
    else if ([13].includes(modelID) && newVal > 6510) {
      _minW = 6710;
    }


    let _newVal = isLanai
      ? _minW + (parseInt((newVal - _minW) / 200) * 200)
      : newVal;

    // handle when new width is max width or min width
    _newVal = newVal == maxW || newVal == _minW ? newVal : _newVal;

    _newVal = [15].includes(modelID) ? _minW + parseInt((newVal - _minW) / 3500) * 3500 : _newVal;
    resetSubsystemWidth();
    setWidth(_newVal);
    addColumns(_newVal);
    state.length.width = _newVal;
    if (
      !snap.isModel &&
      systemID === 0 &&
      newVal * snap.length.depth > 12000000
    ) {
      state.length.depth = parseInt(12000000 / newVal);
      setDepth(parseInt(12000000 / newVal));
    }
    if (
      !snap.isModel &&
      systemID === 6 &&
      OverForte(_newVal, snap.length.depth)
    ) {
      state.length.depth = 3700;
      setDepth(3700);
    }
    handleChangeTop();
  };

  const handleDepthSliderChange = (event, newVal) => {
    if (!validationVal("depth", minD, maxD, newVal)) return false;
    // if (!snap.isModel && systemID === 6 && OverForte(snap.length.width, newVal)) return false;
    let _minD = minD;
    let _newVal = newVal;
    if ([15].includes(modelID)) {
      _newVal = _minD + (parseInt((newVal - _minD) / 200) * 200);
    }
    resetSubsystemWidth();
    setDepth(_newVal);
    state.length.depth = _newVal;
    if (!isValid(_newVal, snap.length.height1, snap.length.height2)) {
      state.length.height2 = getBackHeight(
        _newVal,
        snap.length.height1,
        snap.length.height2
      );
    }
    if (
      !snap.isModel &&
      systemID === 6 &&
      OverForte(snap.length.width, _newVal)
    ) {
      state.length.width = 5000;
      setDepth(5000);
    }
    handleChangeTop();
  };

  const handleHeightSliderChange1 = (event, newVal) => {
    if (!validationVal("height1", minH1, maxH1, newVal)) return false;
    if (!isValid(snap.length.depth, newVal, snap.length.height2)) return false;
    setHeight1(newVal);
    state.length.height1 = newVal;
    if (newVal + diff > snap.length.height2) {
      state.length.height2 = newVal + diff;
      setHeight2(newVal + diff);
    }
    if (!isValid(snap.length.depth, newVal, snap.length.height2)) return false;
  };

  const handleHeightSliderChange2 = (event, newVal) => {
    if (!validationVal("height2", minH2, maxH2, newVal)) return false;
    if (!isValid(snap.length.depth, snap.length.height1, newVal)) return false;
    setHeight2(newVal);
    state.length.height2 = newVal;
  };

  const validationVal = (dimension, min, max, val) => {
    // Capella
    if (modelID === 0 && systemID === 9) { 
      const newWidth = dimension === "width" ? val : width;
      const newDepth = dimension === "depth" ? val : depth;
      if ((newWidth * newDepth) > 18000000) return false;
      else return true;
    } else if (min > val || val > max) return false;
    else return true;
  };

  const handlePlusChange = (type) => {
    switch (type) {
      case "width":
        let wunit = 200;
        if ([15].includes(modelID)) {
          if (snap.length.width === minW) {
            wunit = 3500 - minW;
          } else {
            wunit = 3500;
          }
        } else if (snap.length.width === 6040 && ![19, 17, 4, 13, 14].includes(modelID)) {
          wunit = 270;
        } else if ([19, 4, 14].includes(modelID) && snap.length.width === 6240) {
          wunit = 70;
        } 
        // CABBANA
        else if (modelID === 17 && snap.length.width === 7040) {
          wunit = 270;
        }
        // CABBANA X
        else if (modelID === 13 && snap.length.width === 6440) {
          wunit = 270;
        }
        if (
          validationVal(
            "width",
            minW,
            maxW,
            snap.length.width + (isLanai ? wunit : length_unit)
          )
        ) {
          const newVal = snap.length.width + (isLanai ? wunit : length_unit);
          resetSubsystemWidth();
          setWidth(newVal);
          addColumns(newVal);
          state.length.width = newVal;
          if (
            !snap.isModel &&
            systemID === 6 &&
            OverForte(newVal, snap.length.depth)
          ) {
            state.length.depth = 3700;
            setDepth(3700);
          }
          handleChangeTop();
        }
        break;
      case "depth":
        let dunit = length_unit;
        if ([15].includes(modelID)) {
          dunit = 200;
        }
        if (validationVal("depth", minD, maxD, depth + dunit)) {
          resetSubsystemWidth();
          setDepth(depth + dunit);
          state.length.depth = depth + dunit;
          if (
            !isValid(
              depth + dunit,
              snap.length.height1,
              snap.length.height2
            )
          ) {
            state.length.height2 = getBackHeight(
              depth + dunit,
              snap.length.height1,
              snap.length.height2
            );
          }
          if (
            !snap.isModel &&
            systemID === 6 &&
            OverForte(snap.length.width, depth + dunit)
          ) {
            state.length.width = 5000;
            setDepth(5000);
          }
          handleChangeTop();
        }
        break;
      case "height1":
        if (
          validationVal("height1", minH1, maxH1, height1 + length_unit) &&
          isValid(snap.length.depth, height1 + length_unit, snap.length.height2)
        ) {
          setHeight1(height1 + length_unit);
          state.length.height1 = height1 + length_unit;
          if (
            height1 + 1 + lengths[modelID].diffHeights >
            snap.length.height2
          ) {
            state.length.height2 =
              height1 + length_unit + lengths[modelID].diffHeights;
            setHeight2(height1 + length_unit + lengths[modelID].diffHeights);
          }
        }
        break;
      case "height2":
        if (
          validationVal("height2", minH2, maxH2, height2 + length_unit) &&
          isValid(snap.length.depth, snap.length.height1, height2 + length_unit)
        ) {
          setHeight2(height2 + length_unit);
          state.length.height2 = height2 + length_unit;
        }
        break;
      default:
        break;
    }
  };

  const handleMinusChange = (type) => {
    switch (type) {
      case "width":
        let wunit = 200;
        if ([15].includes(modelID)) {
          if (snap.length.width === 3500) {
            wunit = 3500 - minW;
          } else {
            wunit = 3500;
          }
        } else if (snap.length.width === 6310 && ![19, 4, 14, 17, 13].includes(modelID)) {
          wunit = 270;
        } else if ([19, 4, 14].includes(modelID) && snap.length.width === 6310) {
          wunit = 70;
        }
        
        //CABBANA
        // else if (snap.length.width === 7310 && ![17].includes(modelID)) {
        //   wunit = 270;
        // }
        else if ([17].includes(modelID) && snap.length.width === 7310) {
          wunit = 270;
        }
        
        //CABBANA X
        // else if (snap.length.width === 6710 && ![13].includes(modelID)) {
        //   wunit = 270;
        // }
        else if ([13].includes(modelID) && snap.length.width === 6710) {
          wunit = 270;
        }


        if (
          validationVal("width", minW, maxW, width - (isLanai ? wunit : length_unit))
        ) {
          const newVal = width - (isLanai ? wunit : length_unit);
          resetSubsystemWidth();
          setWidth(newVal);
          addColumns(newVal);
          state.length.width = newVal;
        }
        break;
      case "depth":
        let dunit = length_unit;
        if ([15].includes(modelID)) {
          dunit = 200;
        }
        if (validationVal("depth", minD, maxD, depth - dunit)) {
          resetSubsystemWidth();
          setDepth(depth - dunit);
          state.length.depth = depth - dunit;
          if (
            !isValid(
              depth - dunit,
              snap.length.height1,
              snap.length.height2
            )
          ) {
            state.length.height2 = getBackHeight(
              depth + dunit,
              snap.length.height1,
              snap.length.height2
            );
          }
        }
        break;
      case "height1":
        if (
          validationVal("height1", minH1, maxH1, height1 - length_unit) &&
          isValid(snap.length.depth, height1 - length_unit, snap.length.height2)
        ) {
          setHeight1(height1 - length_unit);
          state.length.height1 = height1 - length_unit;
        }
        break;
      case "height2":
        if (
          validationVal("height2", minH2, maxH2, height2 - length_unit) &&
          isValid(snap.length.depth, snap.length.height1, height2 - length_unit)
        ) {
          setHeight2(height2 - length_unit);
          state.length.height2 = height2 - length_unit;
        }
        break;
      default:
        break;
    }
  };

  const handleReset = (event) => {
    handleWidthSliderChange(
      event,
      snap.isModel
        ? snap.default_width
        : subsystem_lengths[systemID].default_width
    );
    handleDepthSliderChange(
      event,
      snap.isModel
        ? snap.default_depth
        : subsystem_lengths[systemID].default_height
    );
    handleHeightSliderChange1(event, snap.default_height1);
    handleHeightSliderChange2(event, snap.default_height2);
  };

  return (
    <Box>
      <Box
        className={!isMobile ? "overview clearfix" : "clearfix"}
        sx={{ p: 0, pr: { md: 2, xs: 0 }, mb: { md: 0, xs: 2.5 } }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            className="reset-default"
            onClick={(e) => {
              e.preventDefault();
              handleReset(e);
            }}
          >
            <span>
              <RepeatIcon />
            </span>
            <span>{t('Reset to default')}</span>
          </Typography>
        </Box>
        <CustomInput
          {...{
            label: t("Width"),
            type: "width",
            min: minW,
            max: maxW,
            value: snap.length.width,
            unit,
            unitName: snap.length.unitName,
            handleSliderChange: handleWidthSliderChange,
            handlePlusChange,
            handleMinusChange,
          }}
        />
        <CustomInput
          {...{
            label: snap.isModel ? t("Depth") : t("Height"),
            type: "depth",
            min: minD,
            max: snap.isModel ? maxD : maxD,
            value: snap.length.depth,
            unit,
            unitName: snap.length.unitName,
            handleSliderChange: handleDepthSliderChange,
            handlePlusChange,
            handleMinusChange,
          }}
        />
        {snap.isModel ? (
          <>
            <CustomInput
              {...{
                label: secondHeight[modelID] && modelID !== 24
                  ? t("Front Height")
                  : t("Passage Height"),
                type: "height1",
                min: minH1,
                max: maxH1,
                value: snap.length.height1,
                unit,
                unitName: snap.length.unitName,
                handleSliderChange: handleHeightSliderChange1,
                handlePlusChange,
                handleMinusChange,
              }}
            />
            {secondHeight[modelID] && modelID !== 24 ? (
              <CustomInput
                {...{
                  label: t("Back Height"),
                  type: "height2",
                  min: Math.max(minH2, snap.length.height1 + diff),
                  max: maxH2,
                  value: snap.length.height2,
                  unit,
                  unitName: snap.length.unitName,
                  handleSliderChange: handleHeightSliderChange2,
                  handlePlusChange,
                  handleMinusChange,
                }}
              />
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default Dimension;
