import React from 'react'
import logo from '../../assets/models/schildrLogo.glb';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { invertColor } from '../cabbana/Cabbana';

export default function GetLogo(props) {
    const gltf = useLoader(GLTFLoader, logo, loader => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco-gltf/')
        loader.setDRACOLoader(dracoLoader)
    })

    const { nodes, materials } = gltf;
    const { color, columns } = props;
    const { width, depth, height1 } = props.length;
    const { int_width, int_depth, int_height1, unit, oy, oz } = props.int_length;
    const dw = width - int_width;
    const dd = depth - int_depth;
    const dh1 = height1 - int_height1;
    const isNewModel = props.modelID === 21;

    return (
    <>
    <group
        visible={isNewModel}
        position={[(width + 125) * unit / 2, (oy) * unit, (height1 - oz - 120) * unit]}
        scale={100}
    >
        <mesh
          geometry={nodes.Schildr_Logo.geometry}
          material={materials.Logo}
          material-color={invertColor(color)}
          scale={0.6}
          position={[-15, -11.5, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        />
    </group>
    <group
        visible={!isNewModel}
        position={[(width + 125) * unit / 2, (oy) * unit, (height1 - oz - 120) * unit]}
        scale={100}
    >
        <mesh geometry={nodes.Schildr_Logo.geometry} material={materials.Logo}
          material-color={invertColor(color)}
          scale={0.6}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
    </group>
    <group
      visible={!isNewModel}
      position={[(-width - 135) * unit / 2, oy * unit, (height1 - oz - 120) * unit]}
      scale={100}
    >
      <mesh geometry={nodes.Schildr_Logo.geometry} material={materials.Logo}
        material-color={invertColor(color)}
        scale={0.6}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      />
    </group>
    </>
  )

  }
