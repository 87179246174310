import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Cabbana } from "../models/cabbana/Cabbana";
import { CabbanaN } from "../models/cabbana/CabbanaN";
import { CabbanaST } from "../models/cabbana/CabbanaST";
import { Point } from "../models/point/Point";
import { Panora } from "../models/panora/Panora";
import { Urban } from "../models/urban/Urban";
import { UrbanGlass } from "../models/urbanglass/UrbanGlass";
import { Pergola } from "../models/pergola/Pergola";
import { PergolaST } from "../models/pergola/PergolaST";
import { PergolaAvantgarde } from "../models/pergolaavantgarde/PergolaAvantgarde";
import { PergolaAvantgarde1 } from "../models/pergolaavantgarde/PergolaAvantgarde1";
import { Lanai } from "../models/lanai/Lanai";
import { Grande } from "../models/grande/Grande";
import { Roomy } from "../models/roomy/Roomy";
import { Dynamic } from "../models/dynamic/Dynamic";
import { Polad } from "../models/polad/Polad";
import Door from "../subsystems/Door";

import * as constant from "../utils/constant";
import state from "../state";
import { Load } from "./Load";
import { Init } from "./Load";
import { useSnapshot } from "valtio";
import Loader3d from "../common/Loading/Loader3d";
import ModelLoader from "../common/Loading/ModelLoader";
import ProgressBar from "../common/Loading/ProgressBar";
import { lengths } from "../utils/constant";
import { useProgress } from "@react-three/drei";
import AppContext from "context/AppContext";
import RenderModel from "models/cabbana/RenderModel";
import { DynamicN } from "models/dynamic/DynamicN";
import { GrandeN } from "models/grande/GrandeN";
import { RoomyN } from "models/roomy/RoomyN";
import { PoladN } from "models/polad/PoladN";
import { PoladNew } from "models/polad/PoladNew";

export default function MainApp(props) {
  const {
    activeStep,
    setActiveStep,
    setSubStep,
    subStep,
    modelID,
    setModelID,
    setInstall,
    setMode,
    setBrandColor,
  } = props;
  const { mode, setShowprice } = useContext(AppContext);
  const snap = useSnapshot(state);
  const loadingModel =
    localStorage.getItem("loadingModel") === null
      ? null
      : localStorage.getItem("loadingModel");
  const data = localStorage.getItem("data");
  const step = localStorage.getItem("activeStep");
  const [status, setStatus] = React.useState(false);
  const dd =
    (snap.length.depth - 150) / 2 - constant.lengths[modelID].int_depth;
  const rdepth = (snap.length.depth + lengths[modelID].column_thickness) / 2;
  const column_thick = lengths[modelID].column_thickness / 4;
  const user =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user
      : JSON.parse(localStorage.getItem("embed_user"));
  if (mode === 0 && user.brand_color !== null && user.brand_color !== "null")
    setBrandColor(user.brand_color);
  if (mode === 1)
    setShowprice(
      JSON.parse(localStorage.getItem("embed_user")).showprice === 1 ? 1 : 0
    );
  // const { progress } = useProgress();
  // const [loaded, setLoaded] = React.useState(false);
  const unit =
    !user.unit || user.unit === null ? "inch" : user.unit == 0 ? "inch" : "mm";
  localStorage.setItem("unit", unit);

  const render = (modelID) => {
    switch (modelID) {
      case 0:
        return (
          <Point
            scale={0.00005}
            position={[0, 0.18, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 1:
        return snap.length.depth <= 3500 ? (
          <Cabbana
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <Cabbana
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <Cabbana
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );
      case 13:
        return snap.length.depth <= 4250 ? (
          <CabbanaN
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );

      // case 15:
      //     return snap.length.depth <= 6000 ? (
      //         <Cabbana scale={0.005} position={[-1, -0.475, 1.4]} activeStep={activeStep} subStep={subStep} modelID={modelID} setModelID={setModelID} group={props.group}
      //             doubleSystem={false} origin={true} viewMode={false}
      //         />
      //     ) :
      //         (
      //             <>
      //                 <Cabbana scale={0.005} position={[-1, -0.475, 1.4 - rdepth / 4 * 0.001 + column_thick * 0.001]} activeStep={activeStep} subStep={subStep} modelID={modelID} setModelID={setModelID} group={props.group}
      //                     doubleSystem={true} origin={true} viewMode={false}
      //                 />
      //                 <Cabbana scale={0.005} position={[-1, -0.475, 1.4 + rdepth / 4 * 0.001 - column_thick * 0.001]} activeStep={activeStep} subStep={subStep} modelID={modelID} setModelID={setModelID} group={props.group}
      //                     doubleSystem={true} origin={false} viewMode={false}
      //                 />
      //             </>
      //         )
      case 15:
        return snap.length.depth <= 6040 ? (
          <CabbanaN
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );

      case 17:
        // return <CabbanaNew scale={0.005} /* position={[0, 0, 0.75]} */ position={[0.05, -0.475, 0.75]} activeStep={activeStep} subStep={subStep} modelID={modelID} setModelID={setModelID} group={props.group}
        //     doubleSystem={false} origin={true} viewMode={false}
        // />
        return snap.length.depth <= 3300 ? (
          <CabbanaN
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <CabbanaN
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );
      // return <RenderModel modelID={modelID} />;

      case 2:
        return snap.length.depth <= 4500 ? (
          <Panora
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <Panora
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <Panora
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );
      case 8:
        return snap.length.depth <= 4500 ? (
          <Panora
            scale={0.005}
            position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <Panora
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <Panora
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );
      case 4:
        return snap.length.depth <= 3325 ? (
          <Lanai
            scale={0.005}
            position={[-1, -0.475, 1.00549]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <Lanai
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <Lanai
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );

      case 14:
        return snap.length.depth <= 4275 ? (
          <Lanai
            scale={0.005}
            position={[-1, -0.475, 0.886749]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        ) : (
          <>
            <Lanai
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={true}
              viewMode={false}
            />
            <Lanai
              scale={0.005}
              position={[
                -1,
                -0.475,
                1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
              ]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={true}
              origin={false}
              viewMode={false}
            />
          </>
        );

      case 3:
        return (
          <Urban
            scale={0.00005}
            position={[0, 0.18, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 5:
        return (
          <UrbanGlass
            scale={0.00005}
            position={[0, 0.18, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 6:
        return (
          <Grande
            scale={0.00005}
            position={[0, 0.449, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
            isRoomy={false}
          />
        );
      case 7:
        return (
          <Dynamic
            scale={0.00005}
            position={[0, -0.465, 1]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 9:
        return (
          <Pergola
            scale={0.3}
            position={[0, -0.465, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 10:
        return (
          <>
            <Grande
              scale={0.00005}
              position={[0, 0.449, -0.705 - dd * 0.0005]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isRoomy={false}
            />
            <Grande
              scale={0.00005}
              position={[0, 0.449, 0.705 + dd * 0.0005]}
              rotation={[0, Math.PI, 0]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isRoomy={true}
            />
            <Roomy
              scale={0.05}
              position={[0, -0.466, 0]}
              rotation={[0, Math.PI, 0]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
            />
          </>
        );
      case 11:
        return (
          <PergolaAvantgarde
            scale={0.3}
            position={[0, -0.465, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
            isGardes={false}
          />
        );
      case 16:
        return (
          <>
            <PergolaAvantgarde
              scale={0.3}
              position={[0, -0.465, -0.975 - dd * 0.0003]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isGardes={false}
            />
            <PergolaAvantgarde
              scale={0.3}
              position={[0, -0.465, 0.975 + dd * 0.0003]}
              rotation={[0, Math.PI, 0]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isGardes={true}
            />
            {/* <Avantgardes /> */}
          </>
        );
      case 12:
        return (
          <PoladN
            scale={0.3}
            position={[0, -0.465, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 18:
        return (
          <Door
            width={snap.length.width}
            height1={snap.length.height1}
            height2={snap.length.height2}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 19:
          return snap.length.depth <= 3500 ? (
            <CabbanaST
              scale={0.005}
              position={[-1, -0.475, 1.4]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              setModelID={setModelID}
              group={props.group}
              doubleSystem={false}
              origin={true}
              viewMode={false}
            />
          ) : (
            <>
              <CabbanaST
                scale={0.005}
                position={[
                  -1,
                  -0.475,
                  1.4 - (rdepth / 4) * 0.001 + column_thick * 0.001,
                ]}
                activeStep={activeStep}
                subStep={subStep}
                modelID={modelID}
                setModelID={setModelID}
                group={props.group}
                doubleSystem={true}
                origin={true}
                viewMode={false}
              />
              <CabbanaST
                scale={0.005}
                position={[
                  -1,
                  -0.475,
                  1.4 + (rdepth / 4) * 0.001 - column_thick * 0.001,
                ]}
                activeStep={activeStep}
                subStep={subStep}
                modelID={modelID}
                setModelID={setModelID}
                group={props.group}
                doubleSystem={true}
                origin={false}
                viewMode={false}
              />
            </>
          );
      case 20:
        return (
          <PergolaST
            scale={0.3}
            position={[0, -0.465, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
          />
        );
      case 21:
        return (
          <DynamicN
            scale={0.00005}
            position={[0, -0.465, 1]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            doubleSystem={false}
            origin={true}
            viewMode={false}
          />
        );
      case 22:
        // Old working GrandeN
        // return (
        //   <GrandeN
        //     scale={0.005}
        //     position={[-1, -0.475, 1.4]}
        //     activeStep={activeStep}
        //     subStep={subStep}
        //     modelID={modelID}
        //     setModelID={setModelID}
        //     group={props.group}
        //     doubleSystem={false}
        //     origin={true}
        //     viewMode={false}
        //   />
        // )
        return (
          <GrandeN
            scale={0.00005}
            position={[0, 0.449, 0]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            group={props.group}
            isRoomy={false}
          />
        );
      case 23:
        return (
          <>

            <GrandeN
              // right side
              scale={0.00005}
              position={[0, 0.449, -0.705 - dd * 0.0005]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isRoomy={false}
            />
            <GrandeN
              // left side
              scale={0.00005}
              position={[0, 0.449, 0.705 + dd * 0.0005]}
              rotation={[0, Math.PI, 0]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
              isRoomy={true}
            />
            <RoomyN
              scale={0.05}
              position={[0, -0.466, 0]}
              rotation={[0, Math.PI, 0]}
              activeStep={activeStep}
              subStep={subStep}
              modelID={modelID}
              group={props.group}
            />
          </>
        );
      case 24:
        return (
          <PoladNew
            scale={0.3}
            position={[0, -0.465, 0]}
            // scale={0.005}
            // position={[-1, -0.475, 1.4]}
            activeStep={activeStep}
            subStep={subStep}
            modelID={modelID}
            setModelID={setModelID}
            group={props.group}
            // doubleSystem={false}
            // origin={true}
            viewMode={false}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setModelID(modelID);
    setMode(0);
    if (loadingModel === null) {
      Init(modelID);
    } else {
      setInstall(JSON.parse(data).install);
      state.install = JSON.parse(data).install;
      Load(modelID, JSON.parse(data));
      setActiveStep(parseInt(step));
      setSubStep(0);
      localStorage.removeItem("activeStep");
      localStorage.removeItem("data");
      localStorage.removeItem("loadingModel");
    }
    setStatus(true);
  }, []);

  return snap.flag && status ? render(props.modelID) : <Loader3d />;
}
