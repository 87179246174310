import React from 'react';
import SubSystem from '../subsystems/SubSystem';

const rotations = [
    [Math.PI / 2, 0, 0],
    [Math.PI / 2, Math.PI / 2, 0],
    [Math.PI / 2, Math.PI, Math.PI / 2],
    [Math.PI / 2, -Math.PI / 2, 0],
]

function SideInfill(props) {
    const { side, columns, length, int_length } = props;
    const pos_list = [];
    const list = [];

    // left / right
    if (side === 2 || side === 4) {
        if (columns.addedSubSystem[side / 2 - 1]) {
            list.push(
                <SubSystem
                    key={side / 2 - 1}
                    px={(length.width / 2 - (columns.systemId[side === 2 ? 0 : 1] < 3 ? 90 : 30)) * (3 - side)}
                    py={-int_length.oy + length.depth / 2 - props.dy}
                    pz={-int_length.oz}
                    rotation={rotations[side - 1]}
                    width={length.depth - int_length.column_depth}
                    height={length.height1 - props.dz}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    unit={props.unit}
                    ID={side === 2 ? 0 : 1}
                    systemID={columns.systemId[side === 2 ? 0 : 1]}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={1}
                    modelID={props.modelID}
                    columns={columns}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            );
            if (columns.addedForte[side / 2 - 1]) {
                list.push(
                    <SubSystem
                        key={24 + side / 2 - 1}
                        px={(length.width / 2 - (30)) * (3 - side)}
                        py={-int_length.oy + length.depth / 2 - (props.modelID === 7 || props.modelID === 21 ? 100 : 0)}
                        pz={-int_length.oz}
                        rotation={rotations[side - 1]}
                        width={length.depth - int_length.column_depth}
                        height={length.height1 - props.dz}
                        int_div_width={int_length.int_div_width}
                        max_div_width={int_length.max_div_width}
                        unit={props.unit}
                        ID={side === 2 ? 0 : 1}
                        systemID={6}
                        scale={props.unit}
                        type={2}
                        side={side}
                        systemType={2}
                        modelID={props.modelID}
                        columns={columns}
                        isTexture={props.isTexture}
                        texture={props.texture}
                    />
                );
            }
            return list;
        }
        return null;
    }
    else {

        pos_list.push({ pos: columns.pos[0] + int_length.column_thickness, id: 0 });
        pos_list.push({ pos: length.width, id: 0 })

        for (let i = 2; i < 24; i++) {
            if (columns.added[i]) pos_list.push({ pos: columns.pos[i] + int_length.column_thickness / 2, id: i });
            pos_list.sort(function (a, b) { return a.pos - b.pos });
        }

        for (let i = 0; i < pos_list.length - 1; i = i + 1) {
            const ID = pos_list[i].id + 2;
            if (columns.addedSubSystem[ID] === false) continue;
            // console.log(`pos list lenth urban index-${i}`)
            const swidth = pos_list[i + 1].pos - pos_list[i].pos - int_length.column_thickness;
            const px = pos_list[i].pos;
            list.push(
                <SubSystem
                    key={i}
                    px={-px - swidth / 2 + length.width / 2}
                    py={-int_length.oy}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={swidth}
                    height={length.height1 - (props.modelID === 7 || props.modelID === 21 ? 190 : 0)}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={columns.systemId[ID]}
                    unit={props.unit}
                    ID={ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={1}
                    modelID={props.modelID}
                    columns={columns}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
            if (columns.addedForte[ID] === false) continue;
            list.push(
                <SubSystem
                    key={24 + i}
                    px={-px - swidth / 2 + length.width / 2}
                    py={-int_length.oy - (props.modelID === 7 || props.modelID === 21 ? 50 : 0)}
                    pz={-int_length.oz}
                    rotation={rotations[0]}
                    width={swidth}
                    height={length.height1 - props.dz}
                    int_div_width={int_length.int_div_width}
                    max_div_width={int_length.max_div_width}
                    systemID={6}
                    unit={props.unit}
                    ID={ID}
                    scale={props.unit}
                    type={2}
                    side={side}
                    systemType={2}
                    modelID={props.modelID}
                    columns={columns}
                    isTexture={props.isTexture}
                    texture={props.texture}
                />
            )
        }
        return list;
    }
}

export default function SideInfills2(props) {
    const list = [];
    for (let i = 0; i < 4; i = i + 1) {
        if (i === 2) continue;
        if (props.walls.added[4 - i]) continue;
        list.push(
            <SideInfill
                key={i}
                side={i + 1}
                columns={props.columns}
                length={props.length}
                int_length={props.int_length}
                systemID={props.systemID}
                unit={props.int_length.unit}
                dy={props.dy}
                dz={props.dz}
                modelID={props.modelID}
                isTexture={props.isTexture}
                texture={props.texture}
                walls={props.walls}
            />
        )
    }
    return list;
}
