import state from "../state";
import * as constant from "../utils/constant";
import SelectDefaultGlassType from "common/SelectDefaultGlassType";

export function Load(modelID, rdata) {
  const unit =
    localStorage.getItem("unit") === null
      ? "inch"
      : localStorage.getItem("unit");

  const data = typeof rdata === "string" ? JSON.parse(rdata) : rdata;
  state.flag = true;
  state.isDesign = data.isDesign;
  state.isTexture = data.isTexture;
  state.isBladeTexture = data.isBladeTexture;
  state.designStyle = data.designStyle;
  state.hasLight = data.hasLight;
  state.blades = data.blades;
  state.structure = data.structure;
  state.length = data.length;
  state.columns = data.columns;
  state.walls = data.walls;
  state.datasent = data.datasent;
  state.responseMsg = data.responseMsg;
  state.isSubSystem = false;
  state.modelID = modelID;
  state.default_width = data.length.width;
  state.default_depth = data.length.depth;
  state.default_height1 = data.length.height1;
  state.default_height2 = data.length.height2;
  state.length = {
    unit: unit === "inch" ? 1 / 25.4 : 1,
    unitName: unit,
    width: data.length.width,
    depth:
      modelID === 10 && data.length.depth < constant.lengths[modelID].min_depth
        ? data.length.depth * 2 + 150
        : data.length.depth,
    height1: data.length.height1,
    height2: data.length.height2,
  };

  state.subSystemWidth = data.subSystemWidth;
  state.subSystemHeight = data.subSystemHeight;
  state.systemColor = data.systemColor;
  state.systemTexture = data.systemTexture;
  state.systemSide = data.systemSide;
  state.systemColorName = data.systemColorName;
  state.systemSliderCount = data.systemSliderCount;
  state.systemVisible = data.systemVisible;
  state.optionals = data.optionals;
  state.optionalCount = data.optionalCount;
  state.isModel = data.isModel;
  state.systemID = data.systemID;

  state.capellaCount = data.capellaCount;
  state.capellaSize = data.capellaSize;
  state.capellaUnit = data.capellaUnit;
  state.datasent = data.datasent;
  state.responseMsg = data.responseMsg;
  state.autoRotateSpeed = data.autoRotateSpeed;
  state.install = data.install;
  state.unit = data.unit;
  state.unitName = data.unitName;
  state.modelID = data.modelID;
  state.capellaDirection = data.capellaDirection;
  state.forteColor = data.forteColor ? data.forteColor : "#e9e9e4";
  state.forteColorName = data.forteColorName ? data.forteColorName : "WHITE";
}

export function Init(modelID) {
  const unit =
    localStorage.getItem("unit") === null
      ? "inch"
      : localStorage.getItem("unit");
  state.flag = true;
  state.isDesign = null;
  state.isTexture = false;
  state.isBladeTexture = false;
  state.designStyle = null;
  state.hasLight = false;
  state.blades = {
    color:
      modelID === 6 || modelID === 10
        ? "#ffffff"
        : constant.coverColors[modelID][constant.default_blade_colors[modelID]]
            .color,
    texture: constant.hasBlade[modelID].option
      ? constant.wooddesign[modelID][0].url
      : constant.glassType[modelID][0].url,
    textureType: "Frosted",
    rotation: false,
    offset: 0,
    direction: 0,
    opacity: modelID === 6 || modelID === 10 ? 0.7 : 0,
    colorName: "Single Color",
    type: "standart",
  };
  state.structure = {
    color:
      constant.coverColors[modelID][constant.default_cover_colors[modelID]]
        .color,
    texture: constant.woodTexture[0].url,
    textureR: constant.woodTexture[0].urlR,
    textureA: constant.woodTexture[0].urlA,
    textureB: constant.woodTexture[0].urlB,
    textureC: constant.woodTexture[0].urlC,
    textureD: constant.woodTexture[0].urlD,
    textures: [
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
      constant.woodTexture[0].url,
    ],
    colorName: constant.coverColors[modelID][0].title,
  };
  state.length = {
    unit: unit === "inch" ? 1 / 25.4 : 1,
    unitName: unit,
    width: constant.lengths[modelID].default_width,
    depth: constant.lengths[modelID].default_depth,
    height1: constant.lengths[modelID].default_height1,
    height2: constant.lengths[modelID].default_height2,
  };
  state.default_width = constant.lengths[modelID].default_width;
  state.default_depth = constant.lengths[modelID].default_depth;
  state.default_height1 = constant.lengths[modelID].default_height1;
  state.default_height2 = constant.lengths[modelID].default_height2;

  state.columns = {
    count: 0,
    adding: false,
    addingSubSystem: false,
    editing: false,
    editingColumn: null,
    editingSide: 0,
    minPos: 500,
    maxPos: 2100,
    direction: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    removable: [
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    added: [
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    addedSubSystem: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    deletedSubSystem: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    subSystemCount: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    systemPos: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    systemDirection: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    systemId: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    systemLock: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    addedForte: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    fortePos: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    pos: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    side: [
      1, 2, 3, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    isShift: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  };
  const showWall = constant.secondHeight[modelID] || modelID === 24; // new Polad
  state.walls = {
    adding: false,
    added: [false, false, showWall, false],
    addingSide: 0,
  };
  state.datasent = 0;
  state.responseMsg = "";
  state.isModel = true;
  state.isSubSystem = false;
  state.modelID = modelID;
  state.forteColor = "#e9e9e4";
  state.forteColorName = "WHITE";

  if (!constant.secondHeight[modelID]) {
    state.columns.added = [
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
  }

  // if (!constant.secondHeight[modelID] && modelID === 16) {
  //   state.columns.added = [true, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
  // }

  if (modelID === 2 || modelID === 8) {
    state.isBladeTexture = true;
    state.blades.textureType = constant.glassType[modelID][2].title;
    state.blades.texture = constant.glassType[modelID][2].url;
    state.blades.opacity = constant.glassType[modelID][2].opacity;
    state.blades.colorName = constant.glassType[modelID][2].title;
    state.capellaDirection = 1;
  }

  if (modelID === 0) {
    state.isBladeTexture = true;
  }

  if (
    modelID === 1 ||
    modelID === 4 /* || modelID === 2 */ /* || modelID === 8  */ ||
    modelID === 13 ||
    modelID === 15 ||
    modelID === 14
  ) {
    state.columns.systemId = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
      1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    state.columns.addedSubSystem = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
  }

  if (modelID === 2) {
    state.columns.systemId = [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    ];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
  }

  if (modelID === 8) {
    state.columns.systemId = [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1,
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0,
    ];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
  }

  if (modelID === 3 || modelID === 5) {
    // state.columns.addedSubSystem = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, false, false, false, false, false, false, false, false, false];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
    state.columns.systemId = [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    ];
  }

  // if (modelID === 6 || modelID === 7) {
  //   // state.columns.addedSubSystem = [true, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  //   state.columns.addedSubSystem = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  //   state.columns.systemId = [1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  //   state.isBladeTexture = true;
  //   state.blades.opacity = 0
  // }

  if (modelID === 6 || modelID === 22 || modelID === 7 || modelID === 21 /* || modelID === 10 */) {
    // state.columns.addedSubSystem = [true, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
    state.columns.systemId = [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0,
    ];
    state.isBladeTexture = true;
    state.blades.opacity = 0;
  }
  if (modelID === 10 || modelID === 23) {
    state.isBladeTexture = true;
    state.columns.systemId = [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1,
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    ];
    // state.columns.addedSubSystem = [false, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
    //   true, false, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ];
  }
  if (modelID === 16) {
    state.isBladeTexture = true;
    state.columns.systemId = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    // state.columns.addedSubSystem = [false, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
    //   true, false, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    state.columns.addedSubSystem = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    state.columns.systemPos = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70, 70, 70,
    ];
    state.systemColor[23] = "#ffffff";
  }
  if (modelID === 9 || modelID === 11 || modelID === 20) {
    state.columns.systemPos = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70, 70, 70,
    ];
    state.systemColor[23] = "#ffffff";
  }

  if (modelID === 3 || modelID === 0) {
    state.isBladeTexture = true;
    state.blades.texture = constant.glassType[modelID][1].url;
    state.blades.colorName = constant.glassType[modelID][1].title;
    state.blades.opacity = 0;
  }

  if (modelID === 12 || modelID === 24) {
    state.isBladeTexture = true;
    state.columns.systemId = [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1,
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    ];
    // state.columns.addedSubSystem = [false, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
    //   true, false, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    state.columns.addedSubSystem = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ];
  }

  SelectDefaultGlassType(modelID);
}
