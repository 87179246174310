/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { subsystems } from "../utils/constant";
import { useSnapshot } from "valtio";
import state from "../state";
import * as THREE from "three";
import { handleHeight } from "../utils/constant";
import classy from "../assets/models/classy.glb";
import { ClassyGlassCount } from "../models/DivCount";
import { GetHandleColor } from "./GetHandleColor";

const xrepeat = 7,
  yrepeat = 2;

export default function Classy2(props) {
  const { nodes, materials } = useGLTF(classy);
  const { px, py, pz, unit, width, height, systemID } = props;
  const [opacity, setOpacity] = useState(0.3);
  const [color, setColor] = useState("#a7c7cb");
  const snap = useSnapshot(state);
  const handle_color = GetHandleColor();
  // const handle_height = height >= 2000 ? handleHeight + 130 : height / 2 + 130;
  const handle_height = handleHeight + 130;
  const direction = snap.columns.systemDirection[props.ID];
  const isLeft = direction != 0 ? true : false;
  const dr = isLeft ? 1 : -1;

  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const sw = width / int_width;
  const sh = height / int_height;
  const glassWidth = subsystems[systemID].glassWidth;
  // var glassCount = Math.max(2, Math.round((width - 40) / glassWidth, 0));
  var glassCount = Math.max(
    2,
    parseInt(width / subsystems[systemID].sliderWidth)
  );

  glassCount = ClassyGlassCount(
    width,
    glassCount,
    // props.modelID + (props.side % 2 === 0 ? 20 : 0)
    props.modelID
  );
  // if (glassCount > 4 && glassCount % 2) glassCount = glassCount + 1;
  if (glassCount % 2 && snap.columns.systemDirection[props.ID] === 2)
    state.columns.systemDirection[props.ID] = 0;
  if (!snap.isModel) state.editingGlassCount = glassCount;
  state.systemSliderCount[props.ID] = glassCount;

  const div_width = props.isSingular ? width - 40 : (width - 40) / glassCount;

  const selected =
    snap.editingSubSystem && snap.editingSubSystemID === props.ID;

  const material = new THREE.MeshPhongMaterial({
    color: selected ? "#002853" : "#a7c7cb",
    opacity: opacity,
    transparent: true,
  });

  if (glassCount < 4 && snap.columns.systemDirection[props.ID] == 2)
    state.columns.systemDirection[props.ID] = 0;
  if (props.modelID !== 3 && props.modelID !== 5 && glassCount > 5)
    state.columns.systemDirection[props.ID] = 2;
  const texture = useTexture(snap.structure.textures[1]);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(xrepeat, yrepeat);
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
  });

  const list = [];
  var pos = snap.columns.systemPos[props.ID];
  if (snap.columns.systemDirection[props.ID] === 2)
    pos = Math.max(2.5 / (glassCount - 2), pos);

  if (snap.columns.systemDirection[props.ID] != 2 || glassCount % 2) {
    const cnt = props.isSingular ? 1 : glassCount;
    for (let i = 0; i < cnt; i = i + 1) {
      let rank = isLeft ? glassCount - i - 1 : i;
      // Slide to inner
      // if (props.isSingular && props.side === 2) {
      //   rank = isLeft ? 0 : 1;
      // }

      // Fixed slide
      if (props.isSingular && (props.side === 2 || props.side === 4)) {
        rank = 1;
      }
      const start_point = (rank * 100) / (glassCount - 1);
      const move_point =
        (Math.max(0, Math.min(pos, 100 - 8 / glassCount) - start_point) / 100) *
        div_width *
        (glassCount - 1);

      list.push(
        <group
          key={i}
          position={[-div_width * i + move_point * dr, 0, 20 * rank]}
          scale={[div_width / glassWidth, 1, 1]}
        >
          <mesh
            geometry={nodes.leftdoor1profile.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[0, 1.33, -3.48]}
            rotation={[-Math.PI, 0, -Math.PI]}
            scale={1}
          />
          <mesh
            geometry={nodes.leftdoor1glass.geometry}
            material={material}
            position={[-13.26, 1.33, -3.47]}
            rotation={[-Math.PI, 0, -Math.PI]}
            scale={1}
          />
        </group>
      );
    }
  } else {
    const cnt = props.isSingular ? 1 : glassCount / 2;
    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? cnt - i - 1 : i;
      const start_point = (rank * 100) / (cnt - 1);
      const move_point =
        (Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100) *
        div_width *
        (cnt - 1);
      list.push(
        <group
          key={i}
          position={[-div_width * i + move_point * dr, 0, 20 * rank]}
          scale={[div_width / glassWidth, 1, 1]}
        >
          <mesh
            geometry={nodes.leftdoor1profile.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[0, 1.33, -3.48]}
            rotation={[-Math.PI, 0, -Math.PI]}
            scale={1}
          />
          <mesh
            geometry={nodes.leftdoor1glass.geometry}
            material={material}
            position={[-13.26, 1.33, -3.47]}
            rotation={[-Math.PI, 0, -Math.PI]}
            scale={1}
          />
        </group>
      );
    }

    for (let i = 0; i < cnt; i = i + 1) {
      const rank = isLeft ? i : cnt - i - 1;
      const start_point = (rank * 100) / (cnt - 1);
      const move_point =
        (Math.max(0, Math.min(pos, 100 - 6 / cnt) - start_point) / 100) *
        div_width *
        (cnt - 1);
      list.push(
        <group
          key={i + cnt}
          position={[-div_width * (cnt + i) - move_point * dr, 0, 20 * rank]}
          scale={[div_width / glassWidth, 1, 1]}
        >
          <mesh
            geometry={nodes.leftdoor1profile.geometry}
            material={props.isTexture ? material_texture : materials.profile}
            position={[0, 1.33, -3.48]}
            rotation={[-Math.PI, Math.PI, -Math.PI]}
            scale={1}
          />
          <mesh
            geometry={nodes.leftdoor1glass.geometry}
            material={material}
            position={[13.26, 1.33, -3.47]}
            rotation={[-Math.PI, Math.PI, -Math.PI]}
            scale={1}
          />
        </group>
      );
    }
  }

  const silver_material = new THREE.MeshStandardMaterial({
    metalness: 1, // between 0 and 1
    roughness: 0.5, // between 0 and 1
  });
  const handle_list = [];

  if (snap.columns.systemDirection[props.ID] != 2) {
    let rank = isLeft ? glassCount - 1 : 0;
    let start_point = 0;
    // if (props.isSingular && (props.side === 2 || props.side === 4)) {
    //   if (props.side === 2) {
    //     rank = isLeft ? 0 : 1;
    //   }
    //   start_point = (rank * 100) / (glassCount - 1);
    // }

    // Fixed slide
    if (props.isSingular && (props.side === 2 || props.side === 4)) {
      rank = 1;
      start_point = (rank * 100) / (glassCount - 1);
    }
    handle_list.push(
      <React.Fragment key={0}>
        <mesh
          material-color={handle_color}
          material={silver_material}
          geometry={nodes.rightdoor1handle.geometry}
          position={[dr * (width / 2 - 40), handle_height, glassCount * 20]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1}
        />
        <mesh
          material-color={handle_color}
          material={silver_material}
          geometry={nodes.rightdoor1handle.geometry}
          position={[
            dr *
              (-width / 2 +
                20 +
                (Math.max(0, Math.min(pos, 100 - 8 / glassCount) - start_point) / 100) *
                  div_width *
                  (glassCount - 1)),
            handle_height,
            40,
          ]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1}
        />
      </React.Fragment>
    );
  }
  // else {
  //   if (isLeft) {
  //     handle_list.push(
  //       <React.Fragment key={0}>
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[width / 2 - 40, handle_height, glassCount / 2 * 30]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[-width / 2 + 20, handle_height, glassCount / 2 * 30]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[-Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //       </React.Fragment>
  //     )
  //   }
  //   else {
  //     handle_list.push(
  //       <React.Fragment key={0}>
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[20, handle_height, glassCount / 2 * 30]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[-20, handle_height, glassCount / 2 * 30]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[width / 2 - 20 - Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //         <mesh material-color={handle_color} material={silver_material} geometry={nodes.rightdoor1handle.geometry} position={[-width / 2 + 40 + Math.min(pos, 100 - 6 / glassCount * 2) / 100 * div_width * (glassCount / 2 - 1), handle_height, 40]} rotation={[0, -Math.PI / 2, 0]} scale={1} />
  //       </React.Fragment>
  //     )
  //   }
  // }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  var xbias = 0;
  var ybias = 0;
  var xdelta = 0;

  if (props.modelID === 6 || props.modelID === 10) {
    if (props.side % 2) ybias = 25 * unit;
    if (props.side % 2 === 0) xbias = 20 * unit * (props.side - 3);
  }
  if (props.modelID === 7) {
    if (props.side % 2) ybias = 20 * unit;
    if (props.side % 2 === 0) xbias = -30 * unit * (props.side - 3);
    xdelta = -10 * unit;
  }
  if (props.modelID === 9 || props.modelID === 20) {
    if (props.side % 2) ybias = 70 * unit;
    if (props.side % 2 === 0) xbias = 10 * unit * (props.side - 3);
  }
  if (props.modelID === 11 || props.modelID === 16) {
    if (props.side % 2) ybias = 70 * unit;
    if (props.side % 2 === 0) xbias = 40 * unit * (props.side - 3);
  }

  return (
    <group
      rotation={props.rotation}
      scale={[props.scale, props.scale, props.scale]}
      position={[px * unit + xbias + xdelta, py * unit + ybias, pz * unit]}
      onPointerOver={(e) => {
        setOpacity(0.5);
        setColor("#002853");
        document.body.style.cursor = "pointer";
      }}
      onPointerOut={(e) => {
        setOpacity(0.3);
        setColor("#a7c7cb");
        document.body.style.cursor = "auto";
      }}
      onClick={(e) => {
        e.stopPropagation();
        state.editingSubSystem = true;
        state.editingSubSystemID = props.ID;
        state.editingSystemType = props.systemType;
        state.editingGlassCount = glassCount;
      }}
    >
      <group rotation={[0, Math.PI, 0]}>
        <mesh
          geometry={nodes.topprofile.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[0, height, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[sw, 0.75, 1]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.bottomprofile.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[0, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[sw, 0.75, 1]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.leftprofile.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[-width / 2, height / 2, 0]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[sh, 0.75, 1]}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.rightprofile.geometry}
          material={props.isTexture ? material_texture : materials.profile}
          position={[width / 2, height / 2, 0]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
          scale={[sh, 0.75, 1]}
          material-color={snap.structure.color}
        />
        {handle_list}
        <group
          position={[width / 2 - div_width / 2 - 20, height / 2, 0]}
          scale={[1, sh, 1]}
        >
          {list}
        </group>
      </group>
    </group>
  );
}

// useGLTF.preload('models/classy8.glb')
