/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import capella from '../assets/models/capella.glb';
import { subsystems, forteTexture, forteOpacity } from '../utils/constant';
import * as THREE from 'three'
import { useSnapshot } from 'valtio';
import state from '../state';
import { TopDivCount } from '../models/DivCount';

const xrepeat = 0.09,
  yrepeat = 0.03;

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { thickness } = props.int_length;
  const alpha1 = Math.atan(Math.abs(height2 - height1 - thickness) / (depth - thickness));
  return alpha1;
}

export default function Capella2(props) {
  const { nodes, materials } = useGLTF(capella)
  const { systemID } = props;
  const { oz, unit, int_div_width, max_div_width, mid_length_limit } = props.int_length;
  const { width, depth, height1, height2} = props.length;
  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const snap = useSnapshot(state);

  const diffHeight = height2 - height1;
  const height = Math.sqrt(depth * depth + diffHeight * diffHeight);
  const delta = CalcRotation(props);
  const sh = height / int_height;
  const dh = height - int_height;

  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  const isDynamicModel = props.modelID === 7 || props.modelID === 21;

  var div_count = 0;
  if (props.modelID === 3 || props.modelID === 5) {
    if (width >= mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, props.modelID);
    if (width >= mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, props.modelID);
    if (width < mid_length_limit[0]) div_count = TopDivCount(width, props.modelID);
  }
  else if(props.modelID === 6 || isDynamicModel || props.modelID === 10) {
    div_count = TopDivCount(width, props.modelID);
  }
  else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count
  }
  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  const area = width * Math.max(depth, 3000) / 1000 / 1000;
  var width1 = width;
  var width2 = width;
  var width3 = width;
  if (area > 18 && area <= 36) {
      width2 = parseInt(((props.modelID === 10 && props.isRoomy)?(div_count+1):div_count) / 2);
      width1 = div_count - width2;
      width1 = width * width1 / div_count;
      width2 = width * width2 / div_count;
  }
  if (area > 36) {
    const one_area = parseInt(div_count / 3);
    width1 = width3 = one_area;
    width2 = div_count - one_area * 2;
    width1 = width * width1 / div_count;
    width2 = width * width2 / div_count;
    width3 = width * width3 / div_count;
  }
  
  const sw = width / int_width;
  const sw1 = width1 / int_width;
  const sw2 = width2 / int_width;
  
  const texture = useTexture(forteTexture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
    transparent: true,
    opacity: forteOpacity,
  });

  state.columns.systemId[props.ID] = props.systemID;
  state.columns.systemId[props.ID + 1] = props.systemID;
  state.columns.systemId[props.ID - 1] = props.systemID;

  const p1 = Math.min(95, snap.columns.systemPos[props.ID]);
  const p2 = Math.min(95, snap.columns.systemPos[props.ID + 1]);
  const p3 = Math.min(95, snap.columns.systemPos[props.ID - 1]);
  const texture1 = useTexture(props.texture);
  texture1.wrapS = THREE.RepeatWrapping;
  texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(xrepeat, yrepeat);
  const material_texture1 = new THREE.MeshPhysicalMaterial({
    map: texture1,
  });

  const list = [];
  const list1 = [];
  const list2 = [];
  const list3 = [];

  const dz = props.modelID === 7 ? 1150: 0;

  if (area >18) {
    list1.push(
      <group 
        scale={[sw1, 1, sh]}
        position={[-width * 0.01 / 2 + width1 / 2 * 0.01, (height1 + height2) / 2 * 0.01, props.dr>0?0:dh*0.01]}
        rotation={[props.dr * delta + (isDynamicModel?props.alpha:0), isDynamicModel?0:props.alpha, 0]}
      >
        <mesh geometry={nodes.glass.geometry} material={material_texture} position={[-0.96, -1.42, -18.97]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.01, 0.01 * (100 - p1) / 100, 0.01]} 
          material-color={snap.systemColor[props.ID]}
        />
        <mesh geometry={nodes.windowprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor002.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor003.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor005.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor004.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor001.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor006.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.animatedprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85 - 34.15 * p1 / 100]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />

      </group>
    )
    list2.push(
      <group 
        scale={[sw2, 1, sh]}
        position={[-width * 0.01 / 2 + (width1 + width2 / 2) * 0.01, (height1 + height2) / 2 * 0.01, props.dr>0?0:dh*0.01]}
        rotation={[props.dr * delta + (isDynamicModel?props.alpha:0), isDynamicModel?0:props.alpha, 0]}
      >
        <mesh geometry={nodes.glass.geometry} material={material_texture} position={[-0.96, -1.42, -18.97]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.01, 0.01 * (100 - p2) / 100, 0.01]} 
          material-color={snap.systemColor[props.ID]}
        />
        <mesh geometry={nodes.windowprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor002.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor003.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor005.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor004.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor001.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor006.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.animatedprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85 - 34.15 * p2 / 100]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />
      </group>
    )
    list3.push(
      <group 
        scale={[sw1, 1, sh]}
        position={[-width * 0.01 / 2 + (width1 + width2 + width3 / 2) * 0.01, (height1 + height2) / 2 * 0.01, props.dr>0?0:dh*0.01]}
        rotation={[props.dr * delta + (isDynamicModel?props.alpha:0), isDynamicModel?0:props.alpha, 0]}
      >
        <mesh geometry={nodes.glass.geometry} material={material_texture} position={[-0.96, -1.42, -18.97]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.01, 0.01 * (100 - p3) / 100, 0.01]} 
          material-color={snap.systemColor[props.ID]}
        />
        <mesh geometry={nodes.windowprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor002.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor003.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor005.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor004.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor001.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.anchor006.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
          material-color={snap.structure.color}
        />
        <mesh geometry={nodes.animatedprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85 - 34.15 * p3 / 100]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
          material-color={snap.structure.color}
        />

      </group>
    )

  }
  else {
    list.push(
      <group 
        scale={[sw, 1, sh]}
        position={[0, (height1 + height2) / 2 * 0.01, props.dr>0?0:dh*0.01]}
        rotation={[props.dr * delta + (isDynamicModel?props.alpha:0), isDynamicModel?0:props.alpha, 0]}
      >
      <mesh geometry={nodes.glass.geometry} material={material_texture} position={[-0.96, -1.42, -18.97]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.01, 0.01 * (100-p1) / 100, 0.01]} 
          material-color={snap.systemColor[props.ID]}
      />
      <mesh geometry={nodes.windowprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor002.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor003.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor005.geometry} material={props.isTexture?material_texture1:materials.profile} position={[-21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor004.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.35, -1.79]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor001.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.5, 12.64]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.anchor006.geometry} material={props.isTexture?material_texture1:materials.profile} position={[21.02, -2.25, -16.18]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} 
        material-color={snap.structure.color}
      />
      <mesh geometry={nodes.animatedprofile.geometry} material={props.isTexture?material_texture1:materials.profile} position={[20.71, -1.4, 15.85 - 34.15 * p1 / 100]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={0.001} 
        material-color={snap.structure.color}
      />
      </group>
    )
  }

  if (area <= 18) return (
    <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
        }
      }
    >
    {list}
    </group>
  )
  else if(area <= 36) {
    return(
      <>
      <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
        }
      }
    >
      {list1}
    </group>

      <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID + 1;
        }
      }
    >
      {list2}
    </group>
    </>


    )
  }
  else {
    return(
      <>
      <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
        }
      }
    >
      {list1}
    </group>

      <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID + 1;
        }
      }
    >
      {list2}
    </group>

    <group
      scale={100 * unit}
      position={[0, props.py + depth * unit / 2, -oz * unit + props.pz - dz]}
      rotation={[Math.PI / 2, 0, 0]}
      onPointerOver={
        e => {
          document.body.style.cursor = 'pointer';
        }
      }
      onPointerOut={
        e => {
          document.body.style.cursor = 'auto';
        }
      }
      onClick={
        e => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID - 1;
        }
      }
    >
      {list3}
    </group>


    </>


    )
  }

}
